import { connect } from "react-redux";
import { ReduxState } from "../store/reducers";
import { App } from "./App";
import {getProfileByUser, updateMobileLayout} from "../actions/userAction";
import { User } from "../models";

const mapStateToProps = (state: ReduxState) => ({
    user: state.user.data,
    userLoading: state.user.loading,
    userError: state.user.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUserProfile: (id: number, isMobile: boolean) => dispatch(getProfileByUser(id, isMobile)),
    updateMobileLayout: (user: User, isMobile: boolean) => dispatch(updateMobileLayout(user, isMobile))
});

export const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
