import React, { Component } from "react";
import ReactWebChat from "botframework-webchat";
import { DirectLine, DirectLineOptions } from "botframework-directlinejs";
import {
  ChatProps as Props,
  ChatState as State,
  DefaultChatComponentState as DefaultState
} from "../helpers";
import { ChatHistory } from "./components/chatHistory";
import { HcPreviousConversation } from "../../shared/assessments/HcPreviousConversation";
import { Welcome } from "./components/welcome";
import { StartPrompt } from "./components/startPrompt";
import { FilterAssessmentsCriteria, User, Conversation } from "../../../models";
import { RestrictedPrompt } from "./components/restrictedPrompt";

export class ChatComponent extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = DefaultState;
  }

  componentDidMount() {
    this.props.getStores();
    const userId = (window.localStorage.getItem("userId") || '0');
    this.props.fetchUserProfile(parseInt(userId), false);
    this.props.getChatData(userId);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.chatData !== prevProps.chatData) {
      this.setState({chatData: this.props.chatData});
    }
  }

  componentWillUnmount() {
    this.unsubscribeDirectLine();
  }

  // This method will stop working in React 17
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.chatData.directLineToken !== this.props.chatData.directLineToken
    ) {
      this.unsubscribeDirectLine();
      const directLine = new DirectLine({
        token: nextProps.chatData.directLineToken,
      } as DirectLineOptions);
      directLine.activity$.subscribe(activity => {
        switch (activity.type as any) {
          case "endOfConversation":
            // refresh history, select most recent
            this.unsubscribeDirectLine();
            if (this.props.user.ID && this.props.chatData.conversationId) {
              this.props.endOfConversation(
                this.props.user.ID.toString(),
                this.props.chatData.conversationId,
                undefined
              );

              this.props.getConversationHistory(this.props.user.ID, parseInt(this.props.chatData.conversationId,10));
              setTimeout(() => this.setState({ startAssessment: false, filtering: false, excludeOverride: this.props.chatData.chatHistory[0] && this.props.chatData.chatHistory[0].statusId ? this.props.chatData.chatHistory[0].statusId > 2 : false, conversationHistory: { id: this.props.chatData.chatHistory[0].id} as Conversation }), 1000);
            }
        }
      });

      this.setState({
        directLine
      });
    }
  }

  unsubscribeDirectLine = () => {
    if (this.state.directLine != null) {
      (this.state.directLine as DirectLine).end();
    }
  };

  startAssessment = () => {
    if(this.state.directLine){
      this.state.directLine.postActivity({
        from: { id: this.props.user.ID.toString() },
        name: 'requestWelcomeDialog',
        type: 'event',
        value: ''
      }).subscribe(function () {
        console.log('"trigger requestWelcomeDialog" sent');
      });
    }

    this.setState({
      startAssessment: true,
      conversationHistory: {} as Conversation
    });
  };

  cancelFirstTime = () =>
    this.setState({
      chatData: {
        ...this.state.chatData,
        firstTimeUser: false
      }
    });
  cancelFirstTimeAndStartAssessment = (user: User) => {
    user.ID = this.props.user.ID;
    this.props.updateUserProfile(user);
    this.cancelFirstTime();
    this.startAssessment();
  };
  toggleFiltering = (val?: boolean) => {
    this.setState({
      filtering: val !== null ? val === true : !this.state.filtering
    });
  };

  render() {
    const { startAssessment, directLine, filtering } = this.state;
    const { chatData, conversationHistory } = this.props;
    let selectedConversation = conversationHistory
      ? conversationHistory
      : { id: 0 };
    const currentlyExcluded = !!this.props.user.currentlyExcluded || false;
    const isMobile = this.props.user.isMobile;
    return (
      <div className="chatContainer">
        <div className="row">
          {!isMobile && (
            <div className="col-3">
              <ChatHistory
                startAssessment={
                  this.props.user.currentlyExcluded || this.state.excludeOverride
                    ? undefined
                    : this.startAssessment
                }
                chatHistory={chatData.chatHistory}
                selectedConversationId={+selectedConversation.id}
                selectPreviousChat={(chatId: number) => {
                  this.setState({ startAssessment: false, conversationHistory: {id: chatId} as Conversation });
                  this.props.getConversationHistory(this.props.user.ID, chatId);
                }}
                filtering={filtering}
                toggleFiltering={this.toggleFiltering}
                filterAssessments={(criteria: FilterAssessmentsCriteria) =>
                  this.props.filterAssessments(this.props.user.ID, criteria)
                }
              />
            </div>
          )}
          <div className={isMobile ? "col" : "col-9"}>
            <span className="topWarning">If you are reaching out to report potential COVID-19 exposure/sickness, please instead contact your District Manager immediately.</span>
            {!startAssessment && this.state.chatData.firstTimeUser ? (
              <Welcome
                cancelFirstTime={this.cancelFirstTime}
                cancelFirstTimeAndStartAssessment={
                  this.cancelFirstTimeAndStartAssessment
                }
                stores={this.props.stores}
              />
            ) : startAssessment && directLine ? (
              <>
                <ReactWebChat
                  className={`chatBot${isMobile ? " mobile" : ""}`}
                  directLine={directLine}
                  userID={this.props.user.ID + ""}
                  languagePreference={this.props.user.languagePreference}
                />
              </>
            ) : this.state.conversationHistory.id && selectedConversation.hasOwnProperty("items") ? (
              <HcPreviousConversation conversation={conversationHistory} shouldScrollToBottom={true} />
            ) : currentlyExcluded ? (
              <RestrictedPrompt status={this.props.user.status} />
            ) : !!this.startAssessment ? (
              <StartPrompt startAssessment={this.startAssessment} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
