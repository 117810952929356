import * as React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Clinic } from "../../models";
import { Loading } from "../shared/Loading";
import { ErrorMessage } from "../shared/ErrorMessage";

interface Props {
  history: any;
  clinics: Clinic[];
  clinicsLoading: boolean;
  clinicsError: string;
  fetchClinics(): void;
}

export class ClinicList extends React.Component<Props, {}> {
  constructor(props: any) {
    super(props);

    this.state = {};

    this.selectClinic = this.selectClinic.bind(this);
    this.newClinic = this.newClinic.bind(this);
  }

  componentDidMount() {
    this.props.fetchClinics();
  }

  selectClinic = (clinic: Clinic) => (e: any) => {
    this.props.history.push(`/clinic/${clinic.ID}`);
  };

  newClinic() {
    this.props.history.push("/clinic/edit/new");
  }

  render() {
    if (this.props.clinicsLoading) return <Loading />;
    if (this.props.clinicsError)
      return <ErrorMessage error={this.props.clinicsError} />;
      console.log(this.props);

    const searchCriteria: any[] = [];
    if(Array.isArray(this.props.clinics)){
      for(const clinic of this.props.clinics){
        searchCriteria.push({id: clinic.ID, label: clinic.clinicName});
      }
    }
    return (
      <div className="clinicsContainer">
        <button onClick={this.newClinic}>Add a New Clinic</button>
        <h2>Clinic Database
          <span className="toggleContainer">
            <button className="toggleButton selected">List View</button>
            <button className="toggleView" onClick={() => {window.location.href = '/#/clinics/map'}}>Map View</button>
          </span>
        </h2>

        <div id="search">
          <Typeahead placeholder="&#xF002; Search Clinics" onChange={(selected: any) => {
            if(selected[0] && selected[0].id){
              window.location.href = `/#/clinic/${selected[0].id}`;
            }
          }}
         options={searchCriteria} />
          <div className="clinics">
            <table>
              <thead>
                <tr>
                  <th>Clinic Name</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Zip Code</th>
                </tr>
              </thead>
              <tbody>
                {!!this.props.clinics.length &&
                  this.props.clinics.map((clinic, i) => {
                    return (
                      <tr
                        key={i}
                        onClick={this.selectClinic(clinic)}
                        className={"clinic"}
                      >
                        <td>{clinic.clinicName}</td>
                        <td>
                          {clinic.city}, {clinic.state}
                        </td>
                        <td>
                          <span
                            className={clinic.status === "Active" ? "oval" : ""}
                          />
                          {clinic.status}
                        </td>
                        <td>{clinic.zipcode}</td>
                      </tr>
                    );
                  })}
                <tr />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
