import { DirectLine } from "botframework-directlinejs";
import moment from "moment";
import {User, Assessment, FilterAssessmentsCriteria, Store} from "../../models";
import { Conversation } from "../../models/conversation";
import {getProfileByUser} from "../../actions/userAction";

export interface ChatData {
  directLineToken: string;
  conversationId: string;
  tokenExpiresAt: moment.Moment;
  employeeId: number;
  firstTimeUser: boolean;
  chatHistory: Assessment[];
}

export interface ChatProps {
  location: any;

  chatData: ChatData;
  chatDataError: string;
  chatDataLoading: boolean;
  
  user: User;
  userError: string;
  userLoading: boolean;
  
  conversationHistory: Conversation;
  conversationHistoryError: string;
  conversationHistoryLoading: boolean;
  
  stores: Store[];
  storesError: string;
  storesLoading: boolean;

  fetchUserProfile(id: number, isMobile: boolean): void;
  getChatData(id: string): void;
  /**
   * Get selected conversation history to display in chat window
   * @param id EmployeeId
   * @param chatId ConversationId
   */
  getConversationHistory(id: number, chatId: number): void;
  filterAssessments(id: number, criteria: FilterAssessmentsCriteria): void;
  getStores(): void;
  updateUserProfile(user: User): void;
  endOfConversation(id: string, chatId: string, adminId: number|undefined): void;
}

export interface ChatState {
  startAssessment: boolean;
  chatData: ChatData;
  conversationHistory: Conversation;
  directLine?: DirectLine;
  filtering: boolean;
  user: User | undefined;
  excludeOverride: boolean;
}
export const defaultChatData: ChatData = {
  directLineToken: "",
  conversationId: "",
  tokenExpiresAt: moment(),
  employeeId: 0,
  firstTimeUser: true,
  chatHistory: []
};
export const DefaultChatComponentState: ChatState = {
  startAssessment: false,
  chatData: defaultChatData,
  conversationHistory: {} as Conversation,
  directLine: undefined,
  filtering: false,
  user: undefined,
  excludeOverride: false
};
