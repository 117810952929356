import { connect } from 'react-redux';
import { ReduxState } from '../../store/reducers';
import { ClinicDetail } from './clinicDetail';
import {deleteClinic, getClinic} from "../../actions/clinicAction";
import {Clinic} from "../../models";

const mapStateToProps = (state: ReduxState) => ({
    clinic: state.clinic.data,
    clinicLoading: state.clinic.loading,
    clinicError: state.clinic.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchClinic: (id: number) => dispatch(getClinic(id)),
    deleteClinic: (clinic: Clinic) => dispatch(deleteClinic(clinic))
});

export const ClinicsDetailContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClinicDetail);