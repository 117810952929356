import { actionTypes } from "../../common/constants";
import { Clinic } from "../../models";
import { CommonState, DefaultCommonState } from ".";
import {
  CommonStateLoading,
  CommonStateReducer,
  CommonStateError
} from "../../common/util";

export const clinicsReducer = (
  state: CommonState<Clinic[]> = DefaultCommonState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CLINICS_LOADING:
      return CommonStateLoading(state);
    case actionTypes.GET_ALL_CLINICS_COMPLETED:
      return CommonStateReducer(action.payload || []);
    case actionTypes.GET_ALL_CLINICS_FAILED:
      return CommonStateError(action.payload);
  }

  return state;
};

export const clinicReducer = (
  state: CommonState<Clinic> = DefaultCommonState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_CLINIC_COMPLETED:
      return CommonStateReducer(action.payload || {});
    case actionTypes.GET_CLINIC_LOADING:
      return CommonStateReducer(action.payload || {});
    case actionTypes.GET_CLINIC_FAILED:
      return CommonStateReducer(state);
  }

  return state;
};
