import { actionTypes } from "../../common/constants";
import { Role, User } from "../../models";
import {
  CommonStateLoading,
  CommonStateError,
  CommonStateReducer
} from "../../common/util";
import { CommonState, DefaultCommonState } from ".";

export const assessmentsReducer = (
  state: CommonState<User> = DefaultCommonState,
  action: any
): CommonState<User> => {
  switch (action.type) {
    case actionTypes.GET_ALL_ASSESSMENTS_BY_USER_LOADING:
      return CommonStateLoading(state);
    case actionTypes.GET_ALL_ASSESSMENTS_BY_USER_FAILED:
      return CommonStateError(action.payload);
    case actionTypes.GET_ALL_ASSESSMENTS_BY_USER_COMPLETED:
      return CommonStateReducer<User>(action.payload);
  }

  return state;
};

export const usersWithAssessmentsReducer = (
  state: CommonState<User[]> = DefaultCommonState,
  action: any
): CommonState<User[]> => {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_COMPLETED:
      return CommonStateReducer(action.payload);
    case actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_FAILED:
      return CommonStateError(action.payload);
    case actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_LOADING:
      return CommonStateLoading(state);
  }

  return state;
};

export const usersWithAssessmentsByStoreReducer = (
  state: CommonState<User[]> = DefaultCommonState,
  action: any
): CommonState<User[]> => {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_COMPLETED:
      return CommonStateReducer(action.payload);
    case actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_LOADING:
      return CommonStateLoading(state);
    case actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_FAILED:
      return CommonStateError(action.payload);
  }

  return state;
};
