import React from "react";

type Props = {
  startAssessment: Function;
};
export const StartPrompt = (props: Props) => {
  const { startAssessment } = props;
  return (
    <div className="chatBot">
      <div className="emptyAssessment">
        <p className="startAssessmentText">Start A New Assessment</p>
        <p className="selectAssessmentText">Or Select A Previous Assessment</p>
        <button
          onClick={e => {
            e.preventDefault();
            startAssessment();
          }}
          className="startAssessmentButton"
        >
          Start A New Assessment
        </button>
      </div>
    </div>
  );
};
