import moment from "moment";
import { UIDefaults } from "./constants";
import { CommonState, DefaultCommonState } from "../store/reducers";
import { inspect } from "util";
import {User} from "../models";
const { blankChar } = UIDefaults;

export function formatDateForDisplay(
  date: any,
  formatString?: string,
  includeTime: boolean = false,
  includeSeconds: boolean = false
): string {
  if (!date) {
    return blankChar;
  }
  let timeFormat = includeTime ? ` h:mm${includeSeconds ? ":ss" : ""} A` : "";
  if (formatString) return moment(date).format(formatString);
  else if (isThisHour(date)) return moment(date).fromNow();
  else if (isToday(date)) return moment(date).format("h:mm A");
  else if (isThisWeek(date)) return moment(date).format("ddd" + timeFormat);
  else if (isThisYear(date)) return moment(date).format("MMM D" + timeFormat);
  else return moment(date).format("MMM D YYYY" + timeFormat);
}

export const isThisHour = (date: Date) => moment().isSame(moment(date), "hour");
export const isToday = (date: Date) => moment().isSame(moment(date), "day");
export const isThisWeek = (date: Date) => moment().isSame(moment(date), "week");
export const isThisYear = (date: Date) => moment().isSame(moment(date), "year");

export function formatAsRelativeTime(date: any): string {
  if (!date) {
    return blankChar;
  }

  return moment(date).fromNow();
}

export const formatZip = (val: number | string): string => {
  if (val) {
    val = ("" + val).replace(/\D/g, "");
    if (val.length === 5) return val;
    if (val.length < 5) return padLeft(val, 5, "0");
    if (val.length === 9) {
      return `${val.slice(0, 5)}-${val.slice(-4)}`;
    }
    return val;
  }
  return blankChar;
};

export const formatPhone = (val: number | string): string => {
  if (val) {
    val = ("" + val).replace(/\D/g, "");
    let country: string, city: string, number: string;
    switch (val.length) {
      case 10:
        country = "1";
        city = val.slice(0, 3);
        number = val.slice(3);
        break;
      case 11:
        country = val[0];
        city = val.slice(1, 4);
        number = val.slice(4);
        break;
      case 12:
        country = val.slice(0, 3);
        city = val.slice(3, 5);
        number = val.slice(5);
        break;
      default:
        return val;
    }
    if (country === "1") {
      country = "";
    }
    number = `${number.slice(0, 3)}-${number.slice(3)}`;
    return `${country} (${city}) ${number}`.trim();
  }
  return blankChar;
};

export const formatCurrency = (val: number | string): string => {
  let sign = "";
  if (typeof val === "number") {
    if (val < 0) {
      sign = "-";
      val = val * -1;
    }
    val = val.toFixed(2);
  }
  return val ? `${sign}$${val}` : blankChar;
};

export const truncateText = (
  value: string,
  limit: number = 25,
  completeWords: Boolean = false,
  truncatePattern: string = "..."
): string => {
  if (value) {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(" ");
    }
    return `${value.substr(0, limit)}${
      value.length > limit ? truncatePattern : ""
    }`;
  } else {
    return blankChar;
  }
};

export const getIconClass = (id?: number, completed?:boolean) => {
  switch (id) {
    case 1:
      return "pass";
    case 2:
      if(completed){
        return "moderate-completed";
      }else{
        return "moderate";
      }
    case 3:
      if(completed){
        return "fail-completed";
      }else{
        return "fail";
      }
    default:
      return "incomplete";
  }
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
export const padLeft = (
  value: any,
  targetLength: any,
  padString: any
): string => {
  value = value.toString();
  targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
  padString = String(typeof padString !== "undefined" ? padString : " ");
  if (value.length >= targetLength) {
    return String(value);
  } else {
    targetLength = targetLength - value.length;
    if (targetLength > padString.length) {
      const j = padString.length;
      for (let i = 0; i < targetLength / j; i++) {
        padString += padString;
      }
    }
    return padString.slice(0, targetLength) + String(value);
  }
};

/**
 * Generates a non-RFC4122-compliant guid
 * @example const uuid = guid() => '7476c74a-68d0-ef23-0739-08803a7e545d'
 */
export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

/**
 * Returns UI-friendly value from nullable boolean.
 * If null, returns blankChar,
 * If true, returns "Yes"
 * If false, returns "No"
 * @param val boolean
 */
export function yesno(val?: boolean): string {
  return val === null || val === undefined ? blankChar : val ? "Yes" : "No";
}

/** convert file to base64 encoded string to render in img src */
export async function convertToBase64(image: File, cb: Function) {
  try {
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      cb(reader.result);
    };
  } catch (e) {}
}

/** params should be object with keys === route param name */
export function getUriPath(path: string, params?: any): string {
  for (let prop in params) {
    path = path.replace(`:${prop}`, params[prop]);
  }
  return path;
}

export function handleExternalLink(href: string) {
  window.location.assign(href);
}

export const CommonStateLoading = (
  state: CommonState<any>
): CommonState<any> => {
  return {
    ...state,
    loading: true,
  };
};

export const CommonStateError = (
  error: string = UIDefaults.error,
  state: CommonState<any> = DefaultCommonState
): CommonState<any> => {
  if(error.hasOwnProperty("response")) {
    error = (error as any).response.data.message
  }
  return {
    ...state,
    loading: false,
    error
  };
}

export const CommonStateReducer = <T>(
  payload: T,
  combinePreviousStateData: CommonState<T> = DefaultCommonState
): CommonState<T> => {
  return {
    ...combinePreviousStateData,
    data: payload as T
  };
}

export const completedText = (
    completedDate: Date
): string => {
  return `You may return to work on ${new Date(completedDate).toLocaleString()}.`;
};

export const userDisplay = (
    user: User
): string => {
  const employeeNumber:string = user.employeeNumber || '';
  return (user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : employeeNumber);
};