import React from "react";

type Props = {
  isValid: boolean;
  submit: Function;
  text?: string;
  [key: string]: any;
};
export const HcSubmitButton = (props: Props) => {
  const { isValid, submit, text, rest } = props;
  return (
    <>
      <button
        className={`hc btn${isValid ? "" : " disabled"}`}
        type="button"
        disabled={!isValid}
        onClick={e => {
          e.preventDefault();
          if (!isValid) return;
          submit();
        }}
        {...rest}
      >
        {text ? text : "Submit"}
      </button>
    </>
  );
};
