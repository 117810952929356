export const EmployeeConstants = {
  Api: {
    getChatData: "/api/employee/:id/chat",
    getConversationHistory: "/api/employee/:id/chat/:chatId",
    filterAssessments: "/api/employee/:id/chat",
    eoc: "/api/employee/:id/eoc/:chatId/:adminId"
  },
  Actions: {
    GET_CONVERSATION_TOKEN: "GET_CONVERSATION_TOKEN",
    GET_CONVERSATION_TOKEN_ERROR: "GET_CONVERSATION_TOKEN_ERROR",
    GET_CONVERSATION_TOKEN_LOADING: "GET_CONVERSATION_TOKEN_LOADING",

    GET_CONVERSATION_HISTORY: "GET_CONVERSATION_HISTORY",
    GET_CONVERSATION_HISTORY_ERROR: "GET_CONVERSATION_HISTORY_ERROR",
    GET_CONVERSATION_HISTORY_LOADING: "GET_CONVERSATION_HISTORY_LOADING",

    FILTER_ASSESSMENTS: "FILTER_ASSESSMENTS",
    FILTER_ASSESSMENTS_ERROR: "FILTER_ASSESSMENTS_ERROR",
    FILTER_ASSESSMENTS_LOADING: "FILTER_ASSESSMENTS_LOADING",

    CHATDATA_EOC: "CHATDATA_EOC",
    CHATDATA_EOC_ERROR: "CHATDATA_EOC_ERROR",
    CHATDATA_EOC_LOADING: "CHATDATA_EOC_LOADING",

    CONVO_EOC: "CONVO_EOC",
    CONVO_EOC_ERROR: "CONVO_EOCEOC_ERROR",
    CONVO_EOC_LOADING: "CONVO_EOCEOC_LOADING"
  }
};
