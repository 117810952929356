import { combineReducers } from "redux";
import {
  Clinic,
  User,
  Conversation,
  Report,
  Store,
  Export
} from "../../models";
import { clinicsReducer, clinicReducer } from "./clinicsReducer";
import { userReducer, usersReducer } from "./usersReducer";
import { ChatData } from "../../components/employee/helpers";
import {
  chatReducer,
  conversationHistoryReducer
} from "../../components/employee/reducers";
import {
  assessmentsReducer,
  usersWithAssessmentsByStoreReducer,
  usersWithAssessmentsReducer
} from "./assessmentsReducer";
import { exportReducer, reportsReducer } from "./reportsReducer";
import {regionsReducer, storesReducer} from "./storesReducer";
import {Region} from "../../models/region";

export type CommonState<T> = {
  error: string;
  loading: boolean;
  data: T;
};

export const DefaultCommonState: CommonState<any> = {
  error: "",
  loading: false,
  data: {} as any
};

export interface ReduxState {
  clinic: CommonState<Clinic>;
  clinics: CommonState<Clinic[]>;
  user: CommonState<User>;
  users: CommonState<User[]>;
  chatData: CommonState<ChatData>;
  conversationHistory: CommonState<Conversation>;
  userAssessments: CommonState<User>;
  usersWithAssessments: CommonState<User[]>;
  reportData: CommonState<Report[]>;
  stores: CommonState<Store[]>;
  exportData: CommonState<Export[]>;
  usersAndAssessmentsByStore: CommonState<User[]>;
  regions: CommonState<Region[]>;
}

export const state = combineReducers({
  clinic: clinicReducer,
  clinics: clinicsReducer,
  user: userReducer,
  users: usersReducer,
  chatData: chatReducer,
  userAssessments: assessmentsReducer,
  usersWithAssessments: usersWithAssessmentsReducer,
  conversationHistory: conversationHistoryReducer,
  reportData: reportsReducer,
  stores: storesReducer,
  exportData: exportReducer,
  usersAndAssessmentsByStore: usersWithAssessmentsByStoreReducer,
  regions: regionsReducer
});
