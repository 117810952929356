import React, { Fragment } from "react";
import { Role } from "../../models";
import { Link } from "react-router-dom";

const getHomeLink = () => {
  return "/employee";
};

const getDashboardLink = (role: Role) => {
  switch (role) {
    default:
    case Role.Employee:
      return "/employee";
    case Role.Manager:
      return "/managerDashboard";
    case Role.Admin:
      return "/adminDashboard";
  }
};

const employeeLinks = (role: Role, isMobile: boolean) => [
  <Link to={getHomeLink()}>
    <div
      className="logo"
      style={
        isMobile ? { width: 50, height: 50, backgroundSize: "75%" } : {}
      }
    />
    <div 
      className="healthCheck"
      style={
        isMobile ? { width: 65, height: 50, backgroundSize: "75%" } : {}
      } />
  </Link>
];

const managerLinks = (role: Role, isMobile: boolean) => [...employeeLinks(role, isMobile)];
const adminLinks = (role: Role, page: string, isMobile: boolean) => [
  ...managerLinks(role, isMobile),
  <Link
    className={`link ${"/" + page === getDashboardLink(role) ? "active" : ""}`}
    to={getDashboardLink(role)}
  >
    Dashboard
  </Link>,
  <Link
    className={`link ${"/" + page === "reports" ? "active" : ""}`}
    to="/reports"
  >
    Reports
  </Link>,
  <Link
    className={`link ${page === "assessmentLogs" ? "active" : ""}`}
    to="/assessmentLogs"
  >
    Assessment Logs
  </Link>,
  <Link
    className={`link ${
      page === "clinics" || page === "clinic" ? "active" : ""
    }`}
    to="/clinics"
  >
    Clinics
  </Link>
];

const getNavLinks = (role: Role, page: string, isMobile: boolean) => {
  switch (role) {
    default:
    case Role.Employee:
      return employeeLinks(role, isMobile);
    case Role.Manager:
      return managerLinks(role, isMobile);
    case Role.Admin:
      return adminLinks(role, page, isMobile);
  }
};

export interface HeaderNavProps {
  role: Role;
}

export const Nav = (props: any) => {
  const { role, nav, isMobile } = props;
  let links = getNavLinks(role, nav, isMobile);

  return (
    <>
      <div className="col" style={isMobile ? {paddingTop: 9} : { flexGrow: 0.75 }}>
        {links[0]}
      </div>
      {!isMobile && (
        <nav className="col">
          {links.slice(1, links.length).map((x, i) => {
            return <Fragment key={`HeaderNavLink-${i}`}>{x}</Fragment>;
          })}
        </nav>
      )}
    </>
  );
};
