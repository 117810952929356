import { connect } from "react-redux";
import { ReduxState } from "../../store/reducers";
import { Login } from "./login";
import {login} from "../../actions/userAction";

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
    login: () => dispatch(login())
});

export const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
