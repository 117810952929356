import {Store} from "./store";
import {Assessment} from "../models/assessment";
export enum Role {
    Employee,
    Manager,
    Admin
}
export interface User {
    ID: number;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    email: string;
    uniqueKey: string;
    title: string;
    role: Role;
    languagePreference: string;
    phoneNumber: string;
    store: Store;
    assessments: Assessment[];
    currentlyExcluded?: number;
    currentlyRestricted?: number;
    status: string;
    storeNumber?: string;
    totalExcluded?: number;
    employeeNumber?: string;
    isAdmin: boolean | false;
    isMobile: boolean | false;
}
