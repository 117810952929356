import { connect } from 'react-redux';
import { ReduxState } from '../../store/reducers';
import { ClinicEdit } from './clinicEdit';
import {createClinic, getClinic, updateClinic} from "../../actions/clinicAction";
import {Clinic} from "../../models";

const mapStateToProps = (state: ReduxState) => ({
    clinic: state.clinic.data,
    clinicLoading: state.clinic.loading,
    clinicError: state.clinic.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchClinic: (id: number) => dispatch(getClinic(id)),
    updateClinic: (clinic: Clinic) => dispatch(updateClinic(clinic)),
    createClinic: (clinic: Clinic) => dispatch(createClinic(clinic)),
});

export const ClinicsEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClinicEdit);