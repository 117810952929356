import * as React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Clinic, Store } from "../../models";
import { Loading } from "../shared/Loading";
import { ErrorMessage } from "../shared/ErrorMessage";
import { Map } from "../map/map";
import { AppConstants } from "../../common/constants";

interface Props {
  history: any;
  clinics: Clinic[];
  clinicsLoading: boolean;
  clinicsError: string;

  stores: Store[];
  storesLoading: boolean;
  storesError: string;
  fetchClinics(): void;
  fetchStores(): void;
}

export class ClinicMap extends React.Component<Props, {}> {
  constructor(props: any) {
    super(props);

    this.state = {};

    this.selectClinic = this.selectClinic.bind(this);
    this.newClinic = this.newClinic.bind(this);
  }

  componentDidMount() {
    this.props.fetchClinics();
    this.props.fetchStores();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.stores !== prevProps.stores) {
      this.getMap();
    }
  }

  selectClinic = (clinic: Clinic) => (e: any) => {
    this.props.history.push(`/clinic/${clinic.ID}`);
  };

  newClinic() {
    this.props.history.push("/clinic/edit/new");
  }

  getMap() {
    let datasource: any, map: any;
    // @ts-ignore
    map = new atlas.Map("myMap", {
      center: [-70.985708, 40.75773],
      zoom: 3,
      //Add your Azure Maps subscription key to the map SDK. Get an Azure Maps key at https://azure.com/maps
      authOptions: {
        authType: "subscriptionKey",
        subscriptionKey: AppConstants.mapKey,
      },
    });

    for (const store of this.props.stores) {
      if (store.longitude && store.latitude) {
        map.markers.add(
          // @ts-ignore
          new atlas.HtmlMarker({
            color: "#0B4A88",
            position: [store.longitude, store.latitude],
          })
        );
      }
    }

    for (const clinic of this.props.clinics) {
      if (clinic.longitude && clinic.latitude) {
        map.markers.add(
          // @ts-ignore
          new atlas.HtmlMarker({
            color: "#77B200",
            position: [clinic.longitude, clinic.latitude],
          })
        );
      }
    }

    this.setState({ map: map, datasource: datasource });
  }

  render() {
    if (this.props.clinicsLoading || this.props.storesLoading)
      return <Loading />;
    if (this.props.clinicsError || this.props.storesError)
      return (
        <ErrorMessage
          error={this.props.clinicsError || this.props.storesError}
        />
      );

    return (
      <div className="clinicsContainer">
        <button onClick={this.newClinic}>Add a New Clinic</button>
        <h2>
          Clinic Database
          <span className="toggleContainer">
            <button
              className="toggleButton"
              onClick={() => {
                window.location.href = "/#/clinics";
              }}
            >
              List View
            </button>
            <button className="toggleView selected">Map View</button>
          </span>
        </h2>

        <div>
          <div className="mapKey">
            <i className="mapKeyClinic"></i>
            <span>Active Clinic</span> <i className="mapKeyStore"></i>
            <span>EG America Location</span>
          </div>
          <div id="myMap"></div>
        </div>
      </div>
    );
  }
}
