import { default as axios, AxiosRequestConfig, AxiosInstance } from "axios";
import { getAuth } from "../security/auth";
import { AppConstants } from "./constants";

const authed = () =>
  axios.create({
    headers: {
      "x-access-token": getAuth()
    }
  });
export class HttpClient {
  private _client: AxiosInstance;
  constructor(
    baseUrl: string = AppConstants.serverUrl,
    config?: AxiosRequestConfig
  ) {
    config = !!config ? config : ({ baseUrl } as AxiosRequestConfig);
    this._client = axios.create(config);
  }

  login = (username: string, password: string) =>
    axios.post("/authenticate", { username, password }).then(x => x.data);

  get = async <T>(
    path: string,
    errorCallback?: Function
  ): Promise<T | undefined> => {
    try {
      return (await this._client.get<T>(
        `${AppConstants.serverUrl}${path[0] === "/" ? path : `/${path}`}`
      )).data;
    } catch (ex) {
      if (errorCallback) {
        return errorCallback(ex);
      } else this.handleCommonApiError(ex);
    }
  };

  put = async <T>(
    path: string,
    body?: any,
    errorCallback?: Function
  ): Promise<T | undefined> => {
    try {
      return (await this._client.put(
        `${AppConstants.serverUrl}${path[0] === "/" ? path : `/${path}`}`,
        body
      )).data;
    } catch (ex) {
      if (errorCallback) {
        return errorCallback(ex);
      } else this.handleCommonApiError(ex);
    }
  };

  post = async <T>(
    path: string,
    body?: any,
    errorCallback?: Function
  ): Promise<T | undefined> => {
    try {
      return (await this._client.post(
        `${AppConstants.serverUrl}${path[0] === "/" ? path : `/${path}`}`,
        body
      )).data;
    } catch (ex) {
      if (errorCallback) {
        return errorCallback(ex);
      } else this.handleCommonApiError(ex);
    }
  };

  delete = async <T>(
    path: string,
    errorCallback?: Function
  ): Promise<T | undefined> => {
    try {
      return (await this._client.delete(
        `${AppConstants.serverUrl}${path[0] === "/" ? path : `/${path}`}`
      )).data;
    } catch (ex) {
      if (errorCallback) {
        return errorCallback(ex);
      } else this.handleCommonApiError(ex);
    }
  };

  handleCommonApiError = (ex: Error): void => {
    console.error(ex);
  };
}
export default new HttpClient();
