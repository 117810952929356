import { applyMiddleware, compose, createStore, Store } from "redux";
import reduxThunk from "redux-thunk";
import { state, ReduxState } from "./reducers";

export const store: Store<ReduxState> = createStore(
  state,
  compose(applyMiddleware(reduxThunk))
);

export default store;
