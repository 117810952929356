import { connect } from "react-redux";
import { ReduxState } from "../../../store/reducers";
import {
  getChatData,
  getConversationHistory,
  filterAssessments,
  endOfConversation
} from "../actions";
import { ChatComponent } from "./chat";
import { FilterAssessmentsCriteria, User } from "../../../models";
import { getAllStores } from "../../../actions/storeAction";
import {getProfileByUser, updateUserProfile} from "../../../actions/userAction";

const mapStateToProps = (state: ReduxState) => ({
  chatData: state.chatData.data,
  chatDataError: state.chatData.error,
  chatDataLoading: state.chatData.loading,

  conversationHistory: state.conversationHistory.data,
  conversationHistoryError: state.conversationHistory.error,
  conversationHistoryLoading: state.conversationHistory.loading,

  user: state.user.data,
  userError: state.user.error,
  userLoading: state.user.loading,

  stores: state.stores.data,
  storesError: state.stores.error,
  storesLoading: state.stores.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getChatData: (id: string) => dispatch(getChatData(id)),
  getConversationHistory: (id: number, chatId: number) =>
    dispatch(getConversationHistory(id, chatId)),
  getStores: () => dispatch(getAllStores()),
  filterAssessments: (
    id: number,
    assessmentFilterCriteria: FilterAssessmentsCriteria
  ) => dispatch(filterAssessments(id, assessmentFilterCriteria)),
  updateUserProfile: (user: User) => dispatch(updateUserProfile(user)),
  endOfConversation: (id: string, chatId: string, adminId: number|undefined) =>
    dispatch(endOfConversation(id, chatId, adminId)),
  fetchUserProfile: (id: number, isMobile: boolean) => dispatch(getProfileByUser(id, isMobile))
});

export const ChatContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatComponent);
