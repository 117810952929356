import { connect } from 'react-redux';
import { ReduxState } from '../../store/reducers';
import { ClinicList } from './clinicList';
import { getAllClinics } from "../../actions/clinicAction";

const mapStateToProps = (state: ReduxState) => ({
    clinics: state.clinics.data,
    clinicsLoading: state.clinics.loading,
    clinicsError: state.clinics.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchClinics: () => dispatch(getAllClinics()),
});

export const ClinicsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClinicList);