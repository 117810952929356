import { connect } from 'react-redux';
import { ReduxState } from '../../store/reducers';
import {getAllUsers} from "../../actions/userAction";
import {AssessmentLogEmployeeList} from "./assessmentLogEmployeeList";

const mapStateToProps = (state: ReduxState) => ({
    users: state.users.data,
    usersLoading: state.users.loading,
    usersError: state.users.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUsers: () => dispatch(getAllUsers()),
});

export const AssessmentLogEmployeeListContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssessmentLogEmployeeList);