import * as React from "react";
import gif from "./logo-animation-v13.gif";

export function Loading() {
  return (
    <div className="loading-spinner-container">
      <p className="loading-spinner">
        <img src={gif} alt="EG America logo loading animation" />
        {/* <i className="fas fa-spinner fa-7x fa-spin" /> */}
      </p>
    </div>
  );
}
