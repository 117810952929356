import { connect } from 'react-redux';
import { ReduxState } from '../../store/reducers';
import { ClinicMap } from './clinicMap';
import { getAllClinics } from "../../actions/clinicAction";
import { getAllStores } from "../../actions/storeAction";

const mapStateToProps = (state: ReduxState) => ({
    clinics: state.clinics.data,
    clinicsLoading: state.clinics.loading,
    clinicsError: state.clinics.error,

    stores: state.stores.data,
    storesError: state.stores.error,
    storesLoading: state.stores.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchClinics: () => dispatch(getAllClinics()),
    fetchStores: () => dispatch(getAllStores()),
});

export const ClinicsMapContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClinicMap);