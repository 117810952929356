import * as React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import {User} from "../../models";
import { Loading } from "../shared/Loading";
import { ErrorMessage } from "../shared/ErrorMessage";

interface Props {
  users: User[];
  usersLoading: boolean;
  usersError: string;
  fetchUsers(): void;
}

export class AssessmentLogEmployeeList extends React.Component<
  Props,
  {}
> {
  constructor(props: any) {
    super(props);

    this.state = {};

    this.selectEmployee = this.selectEmployee.bind(this);
  }

  componentDidMount(): void {
    this.props.fetchUsers();
  }

  selectEmployee = (employee: User) => (e: any) => {
    window.location.href = `/#/assessmentLogs/${employee.ID}`;
  }

  render() {
    if (this.props.usersLoading) return <Loading />;
    if (this.props.usersError)
        return <ErrorMessage error={this.props.usersError} />;

    const searchCriteria: any[] = [];
    if(Array.isArray(this.props.users)){
      for(const user of this.props.users){
        searchCriteria.push({id: user.ID, label: `${user.employeeNumber} ${user.firstName && user.lastName ? `- ${user.firstName} ${user.lastName}` : ''}`});
      }
    }
    return (
      <div className="assessmentContainer">
        <div className="row">
          <div className="col-3">
            <div className="employeesContainer">
              <Typeahead placeholder="&#xF002; Search Employee" onChange={(selected: any) => {
                if(selected[0] && selected[0].id){
                  window.location.href = `/#/assessmentLogs/${selected[0].id}`;
                }
              }}
              options={searchCriteria} />
              <div className="employees">
                {(this.props.users || []).length && this.props.users.map((user, i) => {
                  return (
                      <div key={i} onClick={this.selectEmployee(user)} className="employee">
                        <div className="row">
                          <div className="col-12">
                            <span className="title">{user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.employeeNumber}</span>
                            <span className="body">{user.storeNumber? `Store # ${user.storeNumber}`:''}</span>
                          </div>
                        </div>
                      </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-9">
            <div className="chatBot">
              <div className="emptyAssessment">
                <p className="startAssessmentText">Select An Employee</p>
                <p className="selectAssessmentText">
                  You can use the filter and search tools to find specific
                  employees
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
