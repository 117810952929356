import * as React from "react";
import {Line} from "react-chartjs-2";
import {Export, Report} from "../../models";
import {CSVLink} from 'react-csv';
import { Loading } from "../shared/Loading";
import { ErrorMessage } from "../shared/ErrorMessage";

interface Props {
  reportData: Report[];
  reportDataLoading: boolean;
  reportDataError: string;

  exportData: Export[];
  exportDataLoading: boolean;
  exportDataError: string;

  fetchReportData(): void;
  fetchExportData(): void;
}

const month: any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export class Reports extends React.Component<
  Props,
  {data: any, csvData: any[]}
> {
  constructor(props: any) {
    super(props);

    this.state = {
      csvData: [],
      data: {
        labels: [],
        datasets: [
          {
            label: "Cleared Employees By Month",
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(119,178,0, 0.8)',
            borderColor: 'rgba(119,178,0, 0.8)',
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(119,178,0, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(119,178,0, 0.8)',
            pointHoverBorderColor: 'rgba(119,178,0, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 2,
            pointHitRadius: 10,
            data: []
          },
          {
            label: "Restriced Employees By Month",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(252, 185, 65, 0.8)",
            borderColor: "rgba(252, 185, 65, 0.8)",
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(252, 185, 65, 0.8)",
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(252, 185, 65, 0.8)",
            pointHoverBorderColor: "rgba(252, 185, 65, 0.8)",
            pointHoverBorderWidth: 2,
            pointRadius: 2,
            pointHitRadius: 10,
            data: []
          },
          {
            label: "Excluded Employees By Month",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(255,0,0, 0.8)",
            borderColor: "rgba(255,0,0, 0.8)",
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,0,0, 0.8)",
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(255,0,0, 0.8)",
            pointHoverBorderColor: "rgba(255,0,0, 0.8)",
            pointHoverBorderWidth: 2,
            pointRadius: 2,
            pointHitRadius: 10,
            data: []
          }
        ]
      }
    };

  }

  componentDidMount() {
    this.props.fetchReportData();
    this.props.fetchExportData();
  }

  componentDidUpdate(prevProps:any): void {
    if (this.props.reportData !== prevProps.reportData) {
      this.updateGraphData()
    }
    if (this.props.exportData !== prevProps.exportData) {
      this.updateCSVData()
    }
  }

  updateCSVData = () => {
    let csvData: any[] = [];

    csvData.push(['First Name','Last Name','Employee Number','Date','Outcome','Cleared Date', 'Admin Name', 'Attestation', 'Store', 'District']);

    for(const userAssessment of this.props.exportData){
      csvData.push([userAssessment.firstName, userAssessment.lastName, userAssessment.employeeNumber, new Date(userAssessment.date).toLocaleString(), userAssessment.outcome.replace(/_/g, ' '), (userAssessment.clearedDate ? new Date(userAssessment.clearedDate).toLocaleString() : ''), userAssessment.adminName, userAssessment.attestation, userAssessment.storeNumber, userAssessment.districtManager]);
    }

    this.setState({csvData: csvData});
  }

  updateGraphData() {
    let data = this.state.data;
    let labels: any[] = [];
    let clearedData: any[] = [];
    let restricedData: any[] = [];
    let excludedData: any[] = [];

    for(const reportMonth of this.props.reportData){
      labels.push(month[reportMonth.month-1]);
      clearedData.push((reportMonth.cleared));
      restricedData.push((reportMonth.restricted));
      excludedData.push((reportMonth.excluded));
    }

    data.labels = labels;
    data.datasets[0].data = clearedData;
    data.datasets[1].data = restricedData;
    data.datasets[2].data = excludedData;
    this.setState({data: data})
  }

  render() {
    const error = this.props.exportDataError || this.props.reportDataError;
    const loading = this.props.exportDataLoading || this.props.reportDataLoading;
    if (loading) return <Loading />;
    if (error) return <ErrorMessage error={error} />;
    return (
      <div className="reportsContainer">
        <CSVLink filename={`conversation-log-export.csv`} data={this.state.csvData} className="export" target="_blank" >Export</CSVLink>
        <h2>Reports</h2>
        <div className="row">
          <div className="col-12">
            <div className="layer">
              <div className="barGraph">
                <Line options={{
                       scales: {
                         yAxes: [{
                           ticks: {
                             beginAtZero: true
                           }
                         }]
                       }
                     }}
                     data={this.state.data}
                     height={120}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
