import React from "react";
import { ConversationItem } from "../../../models/conversation";
import { formatDateForDisplay } from "../../../common/util";

type Props = {
  item: ConversationItem;
};
export const HcAnswer = ({ item }: Props) => {
  if (!item) return <></>;
  const { answer, insertedAt } = item;
  return (
    <>
      <div className="row answer-row">
        <div className="answer">{answer}</div>
        <div className="submitted">
          {formatDateForDisplay(insertedAt, "", true, true)}
        </div>
      </div>
    </>
  );
};
