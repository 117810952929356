import axios from "axios";
import { actionTypes } from "../common/constants";
import { AppConstants } from "../common/constants";
import { User } from "../models";
import { EmployeeConstants } from "../components/employee/constants";
const client = axios.create({ baseURL: AppConstants.serverUrl });

export const getAssessmentsByUser = (userId: number) => (dispatch: any) => {
  dispatch({
    payload: {},
    type: actionTypes.GET_ALL_ASSESSMENTS_BY_USER_LOADING
  });
  client.get(`/api/assessments/getAllAssessmentsByUser?id=${userId}`).then(
    (response: any) => {
      dispatch({
        payload: {},
        type: EmployeeConstants.Actions.GET_CONVERSATION_HISTORY
      });
      dispatch(fetchAssessmentsByUserCompleted(response.data));
    },
    error => {
      dispatch(fetchAssessmentsByUserFailed(error));
    }
  );
};

const fetchAssessmentsByUserCompleted = (user: any) => ({
  payload: user,
  type: actionTypes.GET_ALL_ASSESSMENTS_BY_USER_COMPLETED
});

const fetchAssessmentsByUserFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_ALL_ASSESSMENTS_BY_USER_FAILED
});

export const getAssessment = (id: number) => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.GET_ASSESSMENT_BY_ID_LOADING });
  client.get(`/api/assessments/getAssessmentById?id=${id}`).then(
    (response: any) => {
      dispatch(fetchAssessmentCompleted(response.data));
    },
    error => {
      dispatch(fetchAssessmentFailed(error));
    }
  );
};

const fetchAssessmentCompleted = (assessment: any) => ({
  payload: assessment,
  type: actionTypes.GET_ASSESSMENT_BY_ID_COMPLETED
});

const fetchAssessmentFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_ASSESSMENT_BY_ID_FAILED
});

export const getAssessmentsByStore = (storeId: number) => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.GET_ASSESSMENTS_BY_STORE_LOADING });
  client
    .get(`/api/assessments/getUsersAndAssessmentsByStore?storeId=${storeId}`)
    .then(
      (response: any) => {
        dispatch(fetchAssessmentsByStoreCompleted(response.data));
      },
      error => {
        dispatch(fetchAssessmentsByStoreFailed(error));
      }
    );
};

const fetchAssessmentsByStoreCompleted = (assessments: any) => ({
  payload: assessments,
  type: actionTypes.GET_ASSESSMENTS_BY_STORE_COMPLETED
});

const fetchAssessmentsByStoreFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_ASSESSMENTS_BY_STORE_FAILED
});

export const getAllUsersAndAssessments = () => (dispatch: any) => {
  dispatch({
    payload: {},
    type: actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_LOADING
  });
  client.get(`/api/assessments/getAllUsersAndAssessments`).then(
    (response: any) => {
      dispatch(fetchAllUsersAndAssessmentsCompleted(response.data));
    },
    error => {
      dispatch(fetchAllUsersAndAssessmentsFailed(error));
    }
  );
};

const fetchAllUsersAndAssessmentsCompleted = (assessments: any) => ({
  payload: assessments,
  type: actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_COMPLETED
});

const fetchAllUsersAndAssessmentsFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_FAILED
});

export const getAllUsersAndAssessmentsByStore = (storeId: string) => (
  dispatch: any
) => {
  dispatch({
    payload: {},
    type: actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_LOADING
  });
  client
    .get(`/api/assessments/getAllUsersAndAssessmentsByStore?id=${storeId}`)
    .then(
      (response: any) => {
        dispatch(fetchAllUsersAndAssessmentsByStoreCompleted(response.data));
      },
      error => {
        dispatch(fetchAllUsersAndAssessmentsByStoreFailed(error));
      }
    );
};

const fetchAllUsersAndAssessmentsByStoreCompleted = (assessments: any) => ({
  payload: assessments,
  type: actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_COMPLETED
});

const fetchAllUsersAndAssessmentsByStoreFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_FAILED
});

export const removeRestriction = (user: User, adminId: number) => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.REMOVE_RESTRICTION_LOADING });
  const body = {ID: user.ID, adminID: adminId, attestation: user.assessments[0].attestation};
  client.post(`/api/assessments/removeRestriction`, body).then(
    (response: any) => {
      dispatch(removeRestrictionCompleted(response.data));
    },
    error => {
      dispatch(removeRestrictionFailed(error));
    }
  );
};

const removeRestrictionCompleted = (response: any) => ({
  payload: response,
  type: actionTypes.REMOVE_RESTRICTION_COMPLETED
});

const removeRestrictionFailed = (error: any) => ({
  payload: error,
  type: actionTypes.REMOVE_RESTRICTION_FAILED
});
