import { actionTypes } from "../../common/constants";
import { User, Role } from "../../models";
import { DefaultCommonState, CommonState } from ".";
import {
  CommonStateReducer,
  CommonStateLoading,
  CommonStateError
} from "../../common/util";

export const usersReducer = (
  state: CommonState<User[]> = DefaultCommonState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_USERS_COMPLETED:
      return CommonStateReducer(action.payload || []);
    case actionTypes.GET_USERS_LOADING:
      return CommonStateLoading(state);
    case actionTypes.GET_USERS_FAILED:
      return CommonStateError(action.payload);
  }

  return state;
};

export const userReducer = (
  state: CommonState<User> = DefaultCommonState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE_BY_USER_COMPLETED:
      return handleGetUserCompleted(state.data, action.payload || {});
    case actionTypes.GET_PROFILE_BY_USER_LOADING:
      return CommonStateLoading(state);
    case actionTypes.GET_PROFILE_BY_USER_FAILED:
      return CommonStateError(action.payload);

    case actionTypes.UPDATE_MOBILE_LAYOUT:
      return CommonStateReducer(action.payload || {});
  }

  return state;
};

const handleGetUserCompleted = (state: User, payload: User): CommonState<User> => {
  let role;
  if (payload.isAdmin) {
    role = Role.Admin;
  } else if (!payload.role) {
    role = Role.Employee;
  } else if (payload.title.toLowerCase().includes("manager")) {
    role = Role.Manager;
  } else {
    role = payload.role;
  }

  payload = {
    ...payload,
    role: role
  };

  return {
      error: "",
      loading: false,
      data: payload
  };
};
