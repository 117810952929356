import { actionTypes } from "../../common/constants";
import { Store } from "../../models";
import { DefaultCommonState, CommonState } from ".";
import {
  CommonStateReducer,
  CommonStateError,
  CommonStateLoading
} from "../../common/util";
import {Region} from "../../models/region";

export const storesReducer = (
  state: CommonState<Store[]> = DefaultCommonState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_STORES_COMPLETED:
      return CommonStateReducer(action.payload || []);
    case actionTypes.GET_STORES_LOADING:
      return CommonStateLoading(state);
    case actionTypes.GET_STORES_FAILED:
      return CommonStateError(action.payload);
  }

  return state;
};

export const regionsReducer = (
    state: CommonState<Region[]> = DefaultCommonState,
    action: any
) => {
  switch (action.type) {
    case actionTypes.GET_REGIONS_COMPLETED:
      return CommonStateReducer(action.payload || []);
    case actionTypes.GET_REGIONS_LOADING:
      return CommonStateLoading(state);
    case actionTypes.GET_REGIONS_FAILED:
      return CommonStateError(action.payload);
  }

  return state;
};

