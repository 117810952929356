import React, { Fragment, Component } from "react";
import { HcAssessmentItem } from "../../../shared/assessments/HcAssessmentItem";
import { Assessment, FilterAssessmentsCriteria } from "../../../../models";
import { FilterAssessments } from "./filterAssessments";
import { isNullOrUndefined } from "util";
import moment from "moment";
import { HcOption } from "../../../shared/forms/HcMultiSelectList";

type Props = {
  startAssessment?: Function;
  chatHistory: Assessment[];
  selectedConversationId: number;
  selectPreviousChat: Function;
  filtering: boolean;
  toggleFiltering: Function;
  filterAssessments: Function;
};

type State = {
  startDate?: Date;
  endDate?: Date;
  statusIds?: number[];
  isDirty: boolean;
};
const defaultState = {
  startDate: undefined,
  endDate: undefined,
  statusIds: [],
  isDirty: false
};
// TODO: get this from server
const StatusOptions: HcOption[] = [
  { id: 1, name: "CLEARED FOR WORK", selected: false },
  { id: 2, name: "RESTRICT", selected: false },
  { id: 3, name: "EXCLUDE", selected: false }
];
export class ChatHistory extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = defaultState;
  }

  clear = () => {
    this.setState(defaultState);
  };
  valid = () => {
    let valid = true;
    // We're letting anything and everything be null, but if they have values, make sure they're legit
    const { endDate, startDate, statusIds } = this.state;
    if (!isNullOrUndefined(endDate)) {
      valid = !valid ? false : moment(endDate).isValid();
      if (!isNullOrUndefined(startDate)) {
        valid = !valid ? false : moment(startDate).isBefore(endDate);
      }
    }
    if (!isNullOrUndefined(startDate))
      valid = !valid ? false : moment(startDate).isValid();
    if (!isNullOrUndefined(statusIds) && statusIds.length)
      valid = !valid ? false : statusIds.some(s => +s > 0 && +s < 4);
    return valid;
  };
  update = (key: string, val: any) => {
    this.setState({
      [key]: val,
      isDirty: true
    });
  };
  updateOutcomeFilter = (selectedOutcomes: number[]) => {
    this.setState({
      statusIds: selectedOutcomes,
      isDirty: true
    });
  };
  submit = () => {
    if (!this.valid()) return;
    const { endDate, startDate, statusIds } = this.state;
    this.props.toggleFiltering(null);
    this.props.filterAssessments({
      endDate,
      startDate,
      statusIds
    } as FilterAssessmentsCriteria);
  };

  render() {
    const {
      chatHistory,
      selectedConversationId,
      filtering,
      toggleFiltering
    } = this.props;
    const { startDate, endDate, statusIds } = this.state;
    return (
      <>
        <div className="chatHistory">
          {this.props.startAssessment && (
            <>
              <button
                className="startAssessmentButton button"
                onClick={e => {
                  e.preventDefault();
                  (this.props.startAssessment as Function)();
                }}
              >
                Start A New Assessment <img className="plusSign" />
              </button>
            </>
          )}
          <button
            className="filter-btn button"
            onClick={e => {
              e.preventDefault();
              toggleFiltering(null);
            }}
          >
            Filter Assessments
            {filtering ? (
              <i className="fa fa-times" />
            ) : (
              <i className="fa fa-filter" />
            )}
          </button>
          {filtering ? (
            <FilterAssessments
              {...{
                clear: this.clear,
                submit: this.submit,
                update: this.update,
                updateOutcomes: this.updateOutcomeFilter,
                valid: this.valid,
                startDate,
                endDate,
                statusIds: statusIds || [],
                statusOptions: StatusOptions
              }}
            />
          ) : (
            <>
              <div className="chats">
                {!!chatHistory && chatHistory.length ? (
                  chatHistory.map((x: Assessment, i: number) => (
                    <Fragment key={`chatHistory${x.id}${i}`}>
                      <div
                        onClick={e => {
                          e.preventDefault();
                          this.props.selectPreviousChat(x.id);
                        }}
                      >
                        <HcAssessmentItem
                          assessment={x}
                          index={i}
                          selected={selectedConversationId === +x.id}
                        />
                      </div>
                    </Fragment>
                  ))
                ) : (
                  <HcAssessmentItem
                    assessment={{} as any}
                    index={0}
                    selected={false}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
