import * as React from "react";
import {AppConstants} from "../../common/constants";

export class Login extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.location.href = AppConstants.loginUrl;
  }

  render() {
    return (
      <div className="loginContainer">
        <div className="logo" />
        <div className="healthCheck" />
        <div style={{ textAlign: "center", paddingTop: "25%" }}>
          Redirecting to company login...
        </div>
      </div>
    );
  }
}
