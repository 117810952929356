import { connect } from 'react-redux';
import { ReduxState } from '../../store/reducers';
import {AssessmentLogList} from "./assessmentLogList";
import {getAssessmentsByUser} from "../../actions/assessmentAction";
import {filterAssessments, getChatData, getConversationHistory} from "../employee/actions";
import {FilterAssessmentsCriteria} from "../../models";

const mapStateToProps = (state: ReduxState) => ({
    chatData: state.chatData.data,
    chatDataLoading: state.chatData.loading,
    chatDataError: state.chatData.error,

    conversationHistory: state.conversationHistory.data,
    conversationHistoryLoading: state.conversationHistory.loading,
    conversationHistoryError: state.conversationHistory.error,
    
    userAssessments: state.userAssessments.data,
    userAssessmentsLoading: state.userAssessments.loading,
    userAssessmentsError: state.userAssessments.error,
    
    user: state.user.data,
    userLoading: state.user.loading,
    userError: state.user.error
});

const mapDispatchToProps = (dispatch: any) => ({
    getAssessmentsByUser: (id: number) => dispatch(getAssessmentsByUser(id)),
    getChatData: (id: string) => dispatch(getChatData(id)),
    getConversationHistory: (id: number, chatId: number) =>
        dispatch(getConversationHistory(id, chatId)),
    filterAssessments: (
        id: number,
        assessmentFilterCriteria: FilterAssessmentsCriteria
    ) => dispatch(filterAssessments(id, assessmentFilterCriteria))
});

export const AssessmentLogListContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssessmentLogList);