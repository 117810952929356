import { actionTypes } from "../../common/constants";
import { Report, Export } from "../../models";
import {
  CommonStateReducer,
  CommonStateError,
  CommonStateLoading
} from "../../common/util";
import { CommonState, DefaultCommonState } from ".";

export const reportsReducer = (
  state: CommonState<Report[]> = DefaultCommonState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_REPORT_DATA_COMPLETED:
      return CommonStateReducer(action.payload || []);
    case actionTypes.GET_REPORT_DATA_LOADING:
      return CommonStateLoading(state);
    case actionTypes.GET_REPORT_DATA_FAILED:
      return CommonStateError(action.payload);
  }

  return state;
};

export const exportReducer = (
  state: CommonState<Report[]> = DefaultCommonState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_EXPORT_DATA_COMPLETED:
      return CommonStateReducer(action.payload || []);
    case actionTypes.GET_EXPORT_DATA_LOADING:
      return CommonStateLoading(state);
    case actionTypes.GET_EXPORT_DATA_FAILED:
      return CommonStateError(action.payload);
  }

  return state;
};
