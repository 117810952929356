import * as React from "react";
import {AppConstants} from "../../common/constants";

interface Props {
    data: any[];
    cluster: boolean;
}

export class Map extends React.Component<
    Props,
    { map: any; datasource: any; popups: any; }
    > {
    constructor(props: any) {
        super(props);

        this.state = {
            map: null,
            datasource: null,
            popups: null
        };

        this.showPopup = this.showPopup.bind(this);
    }

    componentDidMount() {
        this.getMap();
    }

    componentDidUpdate(prevProps: any) {
        // Typical usage (don't forget to compare props):
        if (this.props.data !== prevProps.data) {
            this.getMap();
        }
    }

    getMap(){
        let datasource: any, map: any;

        if(!this.props.cluster){
            for(let i = 0; i < this.props.data.length; i++){
                const marker = this.props.data[i];
                if(i === 0){
                    // @ts-ignore
                    map = new atlas.Map("myMap", {
                        center: [marker.longitude, marker.latitude],
                        zoom: 15,
                        //Add your Azure Maps subscription key to the map SDK. Get an Azure Maps key at https://azure.com/maps
                        authOptions: {
                            authType: 'subscriptionKey',
                            subscriptionKey: AppConstants.mapKey
                        }
                    });
                    // @ts-ignore
                    map.markers.add(new atlas.HtmlMarker({
                        color: '#77B200',
                        position: [marker.longitude, marker.latitude]
                    }));
                }else{
                    if(marker.longitude && marker.latitude){
                        // @ts-ignore
                        map.markers.add(new atlas.HtmlMarker({
                            color: '#0B4A88',
                            position: [marker.longitude, marker.latitude]
                        }));
                    }
                }
            }
            this.setState({map: map});
        }else{
            // @ts-ignore
            map = new atlas.Map("myMap", {
                center: [-70.985708, 40.75773],
                zoom: 3,
                //Add your Azure Maps subscription key to the map SDK. Get an Azure Maps key at https://azure.com/maps
                authOptions: {
                    authType: 'subscriptionKey',
                    subscriptionKey: 'RenyejBwMxSlrqifqDMYxbvBP0H5nkJf0SHo6XA27Ik'
                }
            });

            //Wait until the map resources are ready.
            map.events.add('ready', () => {
                //Create a data source and add it to the map.
                // @ts-ignore
                datasource = new atlas.source.DataSource(null, {
                    //Tell the data source to cluster point data.
                    cluster: true,
                    //The radius in pixels to cluster points together.
                    clusterRadius: 10,
                    //The maximium zoom level in which clustering occurs.
                    //If you zoom in more than this, all points are rendered as symbols.
                    clusterMaxZoom: 15
                });
                map.sources.add(datasource);

                //Create a bubble layer for rendering clustered data points.
                // @ts-ignore
                var clusterBubbleLayer = new atlas.layer.BubbleLayer(datasource, null, {
                    //Scale the size of the clustered bubble based on the number of points inthe cluster.
                    radius: [
                        'step',
                        ['get', 'point_count'],
                        20,         //Default of 20 pixel radius.
                        100, 30,    //If point_count >= 100, radius is 30 pixels.
                        750, 40     //If point_count >= 750, radius is 40 pixels.
                    ],

                    //Change the color of the cluster based on the value on the point_cluster property of the cluster.
                    color: [
                        'step',
                        ['get', 'point_count'],
                        'rgba(0,255,0,0.8)',            //Default to green.
                        100, 'rgba(255,255,0,0.8)',     //If the point_count >= 100, color is yellow.
                        750, 'rgba(255,0,0,0.8)'        //If the point_count >= 100, color is red.
                    ],
                    strokeWidth: 0,
                    filter: ['has', 'point_count'] //Only rendered data points which have a point_count property, which clusters do.
                });

                //Add mouse events to change the mouse cursor when hovering over a cluster.
                map.events.add('mouseenter', clusterBubbleLayer, function () {
                    map.getCanvas().style.cursor = 'pointer';
                });
                map.events.add('mouseleave', clusterBubbleLayer, function () {
                    map.getCanvas().style.cursor = '';
                });
                //Add the clusterBubbleLayer and two additional layers to the map.
                map.layers.add([
                    clusterBubbleLayer,
                    //Create a symbol layer to render the count of locations in a cluster.
                    // @ts-ignore
                    new atlas.layer.SymbolLayer(datasource, null, {
                        iconOptions: {
                            image: 'none' //Hide the icon image.
                        },
                        textOptions: {
                            textField: ['get' , 'point_count_abbreviated'],
                            offset: [0, 0.4]
                        }
                    }),
                    //Create a layer to render the individual locations.
                    // @ts-ignore
                    new atlas.layer.SymbolLayer(datasource, null, {
                        filter: ['!', ['has', 'point_count']] //Filter out clustered points from this layer.
                    })
                ]);

                const popups: any[] = [];
                for (var i = 0; i < this.props.data.length; i++) {
                    //Create a popup.
                    // @ts-ignore
                    popups.push(new atlas.Popup({
                        content: `<div style="padding:10px;" aria-label="Description">${this.props.data[i].content}</div>`,
                        position: this.props.data[i].geometry.coordinates,
                        pixelOffset: [0, -18]
                    }));

                    //Create a point feature and store the popups index from the array.
                    // @ts-ignore
                    datasource.add(new atlas.data.Feature(new atlas.data.Point(this.props.data[i].geometry.coordinates), {
                        popupIdx: popups.length - 1
                    }));
                }

                //Add all the popups to the map.
                map.popups.add(popups);

                //Add a symbol layer to display the point features on the map.
                // @ts-ignore
                var layer = new atlas.layer.SymbolLayer(datasource);
                map.layers.add(layer);

                // //Add a click event the
                // map.events.add('click', layer, showPopup);
                //
                // //Retrieve a GeoJSON data set and add it to the data source.
                // datasource.add(this.props.data);

                map.events.add('click', layer, this.showPopup);
                this.setState({map: map, datasource, popups});
            });
        }
    }

    showPopup(e: any) {
        //Get the point feature the click event occured on.
        const point = e.shapes[0];

        //Get the popup for the point feature.
        const popup = this.state.popups[point.getProperties().popupIdx];

        //Open the popup.
        popup.open(this.state.map);
    }

    render() {
        return (
            <div id="myMap"></div>
        );
    }
}
