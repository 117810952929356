import React from "react";
import { ConversationItem } from "../../../models/conversation";
import { formatDateForDisplay } from "../../../common/util";

type Props = {
  item: ConversationItem;
};
export const HcConversationImage = ({ item }: Props) => {
  if (!item) return <></>;
  const { imageUrl, imageName, fromBot, insertedAt } = item;
  const side = fromBot ? "question" : "answer";
  // Switch on imageContentType if non-image formats are ever added
  return (
    <>
      <div className={`row ${side}-row`}>
        <div className={`${side}`}>
          <img src={imageUrl} title={imageName} />
        </div>
        <div className="submitted">
          {formatDateForDisplay(insertedAt, "", true, true)}
        </div>
      </div>
    </>
  );
};
