import React, { Fragment } from "react";

export type HcOption = {
  id: any;
  selected: any;
  name: any;
};

type HcMultiSelectParams = {
  updateMethod: Function;
  name: string;
  value: number[];
  options: HcOption[];
  [key: string]: any;
};

export const HcMultiSelectList = ({
  updateMethod,
  name,
  value,
  options,
  ...props
}: HcMultiSelectParams): JSX.Element => {
  return (
    <Fragment key={`${name}HcMultiSelectFragment`}>
      <div className="multi-select">
        {options.map((o: HcOption, i: number) => {
          let selected = value.some((x: number) => x === o.id);
          return (
            <Fragment key={`${o.name}-${o.id}-${i}`}>
              <div
                className="clickable"
                onClick={e => {
                  e.preventDefault();
                  let newSelected = selected
                    ? value.filter((x: number) => x !== o.id)
                    : [...value, o.id];
                  updateMethod(newSelected);
                }}
                {...props}
              >
                <i
                  className={`fa ${
                    selected ? "fa-check-square" : "fa-square-o"
                  }`}
                />
                {o.name}
              </div>
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};
