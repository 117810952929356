import { connect } from 'react-redux';
import { ReduxState } from '../../store/reducers';
import { AdminDashboard } from './adminDashboard';
import {
    getAllUsersAndAssessments,
    getAllUsersAndAssessmentsByStore,
    removeRestriction
} from "../../actions/assessmentAction";
import {User} from "../../models";
import {getAllRegions} from "../../actions/storeAction";

const mapStateToProps = (state: ReduxState) => ({
    usersAndAssessments: state.usersWithAssessments.data,
    usersAndAssessmentsLoading: state.usersWithAssessments.loading,
    usersAndAssessmentsError: state.usersWithAssessments.error,

    usersAndAssessmentsByStore: state.usersAndAssessmentsByStore.data,
    usersAndAssessmentsByStoreLoading: state.usersAndAssessmentsByStore.loading,
    usersAndAssessmentsByStoreError: state.usersAndAssessmentsByStore.error,
    
    user: state.user.data,
    userLoading: state.user.loading,
    userError: state.user.error,

    regions: state.regions.data,
    regionsLoading: state.regions.loading,
    regionsError: state.regions.error,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchAllUsersAndAssessmentsByStore: (storeId: string) => dispatch(getAllUsersAndAssessmentsByStore(storeId)),
    fetchAllUsersAndAssessments: () => dispatch(getAllUsersAndAssessments()),
    removeRestrictions: (user: User, userId: number) => dispatch(removeRestriction(user, userId)),
    fetchRegions: () => dispatch(getAllRegions())
});

export const AdminDashboardContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminDashboard);