import React, { Component } from "react";
import ReactWebChat from "botframework-webchat";
import { DirectLine, DirectLineOptions } from "botframework-directlinejs";
import {
  defaultChatData,
  ChatProps as Props,
  ChatState
} from "../employee/helpers";
import {Conversation, User} from "../../models";

interface State extends ChatState{
  impersonatedId: string|undefined;
}

export class ImpersonateComponent extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      startAssessment: false,
      chatData: defaultChatData,
      conversationHistory: {} as Conversation,
      directLine: undefined,
      filtering: false,
      user: undefined,
      impersonatedId: undefined,
      excludeOverride: false
    };
  }

  componentDidMount() {
    const userId = this.props.location.pathname.toString().replace('/impersonate/','');
    this.props.getChatData(userId);
    this.setState({impersonatedId: userId});
  }

  componentWillUnmount() {
    this.unsubscribeDirectLine();
  }

  // This method will stop working in React 17
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.chatData.directLineToken !== this.props.chatData.directLineToken
    ) {
      this.unsubscribeDirectLine();
      const directLine = new DirectLine({
        token: nextProps.chatData.directLineToken
      } as DirectLineOptions);
      directLine.activity$.subscribe(activity => {
        switch (activity.type as any) {
          case "endOfConversation":
            // refresh history, select most recent
            this.unsubscribeDirectLine();
            if (this.state.impersonatedId && this.props.user.ID && this.props.chatData.conversationId) {
              this.props.endOfConversation(
                this.state.impersonatedId,
                this.props.chatData.conversationId,
                this.props.user.ID
              );

              window.location.href = '/#/managerDashboard';
            }
        }
      });

      if(directLine && this.state.impersonatedId){
        directLine.postActivity({
          from: { id: this.state.impersonatedId.toString() },
          name: 'requestWelcomeDialog',
          type: 'event',
          value: ''
        }).subscribe(function () {
          console.log('"trigger requestWelcomeDialog" sent');
        });
      }

      this.setState({
        directLine
      });
    }
  }

  unsubscribeDirectLine = () => {
    if (this.state.directLine != null) {
      (this.state.directLine as DirectLine).end();
    }
  };

  render() {
    const { directLine } = this.state;
    return (
      <div className="chatContainer impersonate">
        <div className="row">
          <div className={"col-12"} style={{ height: 640 }}>
            { directLine ?
                <>
                  <span className="impersonation">Taking assessment for employee # {this.state.impersonatedId}</span>
                  <ReactWebChat
                    className={`chatBot`}
                    directLine={directLine}
                    userID={this.state.impersonatedId + ""}
                    languagePreference={this.props.user.languagePreference}
                  />
                </>
            : ''}
          </div>
        </div>
      </div>
    );
  }
}
