import * as React from "react";
import { Bar } from "react-chartjs-2";
import Modal from "react-modal";
import { Map } from "../map/map";
import {Store, User} from "../../models";
import { Loading } from "../shared/Loading";
import { ErrorMessage } from "../shared/ErrorMessage";
import {Region} from "../../models/region";
import {
  userDisplay
} from "../../common/util";

interface Props {
  user: User;
  userLoading: boolean;
  userError: string;

  usersAndAssessments: User[];
  usersAndAssessmentsLoading: boolean;
  usersAndAssessmentsError: string;

  usersAndAssessmentsByStore: User[];
  usersAndAssessmentsByStoreLoading: boolean;
  usersAndAssessmentsByStoreError: string;

  regions: Region[];
  regionsLoading: boolean;
  regionsError: string;

  fetchAllUsersAndAssessments(): void;
  fetchAllUsersAndAssessmentsByStore(storeId: string): void;
  removeRestrictions(user: User, userId: number): void;
  fetchRegions(): void;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "75%",
    overflow: "scroll"
  }
};

export class AdminDashboard extends React.Component<
  Props,
  {
    excludedEmployees: User[];
    restrictedEmployees: User[];
    data: any;
    mapData: any;
    modalIsOpen: boolean;
    employeesToClear: User[];
    selectedRegion: number | undefined;
    selectedStore: Store;
  }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedRegion: undefined,
      selectedStore: {} as Store,
      modalIsOpen: false,
      mapData: [],
      excludedEmployees: [],
      restrictedEmployees: [],
      employeesToClear: [],
      data: {
        labels: [],
        datasets: [
          {
            label: "Exclusions By District",
            backgroundColor: "rgba(119,178,0, 0.8)",
            borderColor: "rgba(119,178,0, 0.8)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(119,178,0, 0.8)",
            hoverBorderColor: "rgba(119,178,0, 0.8)",
            data: []
          }
        ]
      }
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.submitRemoval = this.submitRemoval.bind(this);
  }

  componentDidMount() {
    if(!this.props.user.ID && !this.props.userLoading){
      window.location.href = '/';
    }

    this.props.fetchRegions();
    this.props.fetchAllUsersAndAssessments();

    window.onpopstate  = (e) => {
      if(this.state.selectedStore.id || this.state.selectedRegion){
        window.history.go(1);
        this.cancelSelection();
      }
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.usersAndAssessments !== prevProps.usersAndAssessments) {
      this.updateData();
    }
  }

  openModal() {
    if (this.state.excludedEmployees.length > 0) {
      this.setState({ modalIsOpen: true });
    }
  }

  closeModal() {
    return this.setState({ modalIsOpen: false, employeesToClear: [] });
  }

  updateData() {
    let mapData: any[] = [];
    let excludedEmployees: any[] = [];
    let restrictedEmployees: any[] = [];
    let districtsValues: any = {};
    let popupCount: number = 0;

    for (const user of this.props.usersAndAssessments) {
      if(user.store){
        if (districtsValues[user.store.districtManager]) {
          districtsValues[user.store.districtManager] += user.currentlyExcluded;
        } else {
          districtsValues[user.store.districtManager] = user.currentlyExcluded;
        }

        if (user.currentlyExcluded && user.currentlyExcluded > 0) {
          excludedEmployees.push(user);

          mapData.push({
            popupIdx: popupCount,
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [user.store.longitude, user.store.latitude]
            },
            content: `<div style="padding:10px;" aria-label="Description">Store #${user.storeNumber}</div>`,
          });
          popupCount = popupCount + 1;
        }

        if (user.currentlyRestricted && user.currentlyRestricted > 0) {
          restrictedEmployees.push(user);
        }
      }
    }

    let data = this.state.data;
    let labels: any[] = [];
    let labelData: any[] = [];
    for (const key of Object.keys(districtsValues)) {
      labels.push(key);
      labelData.push(districtsValues[key]);
    }

    data.labels = labels;
    data.datasets[0].data = labelData;
    this.setState({
      mapData,
      excludedEmployees,
      data,
      restrictedEmployees
    });
  }

  selectRegion = (regionIndex: number) => (e: any) => {
    this.setState({selectedRegion: regionIndex});
  }

  selectStore = (store: Store) => (e: any) => {
    this.props.fetchAllUsersAndAssessmentsByStore(store.id.toString());
    this.setState({selectedStore: store});
  }

  cancelSelection() {
    if(this.state.selectedStore.id){
      this.setState({selectedStore: {} as Store});
    }else if(this.state.selectedRegion){
      this.setState({selectedRegion: undefined});
    }
  }

  selectUser = (user: User) => (e: any) => {
    let employeesToClear = this.state.employeesToClear;
    const index = employeesToClear.indexOf(user);
    if (index === -1) {
      employeesToClear.push(user);
    } else {
      employeesToClear =
        employeesToClear.length === 1 ? [] : employeesToClear.splice(index, 1);
    }

    this.setState({ employeesToClear: employeesToClear });
  };

  updateUserAttestation = (user: User) => (e: any) => {
    let employeesToClear = this.state.employeesToClear;
    let index = employeesToClear.indexOf(user);
    if (index === -1) {
      let excludedEmployees = this.state.excludedEmployees;
      index = this.state.excludedEmployees.indexOf(user);
      excludedEmployees[index].assessments[0].attestation = e.target.value;
      this.setState({ excludedEmployees: excludedEmployees });
    } else {
      employeesToClear[index].assessments[0].attestation = e.target.value;
      this.setState({ employeesToClear: employeesToClear });
    }
  };

  submitRemoval() {
    for(const user of this.state.employeesToClear){
      this.props.removeRestrictions(user, this.props.user.ID);
    }
    this.setState({ modalIsOpen: false });

    setTimeout(() => {
      this.props.fetchAllUsersAndAssessments();
    }, 1000);
  }

  validation() {
    if((this.state.employeesToClear || []).length === 0){
      return true;
    }

    let missingAttestation: boolean = false;
    for(const user of this.state.employeesToClear){
      if(!user.assessments[0].attestation || user.assessments[0].attestation === ''){
        missingAttestation = true;
        break;
      }
    }

    return missingAttestation;
  }

  render() {
    if (this.props.usersAndAssessmentsLoading || this.props.userLoading)
      return (
        <Loading />
      );
    if (this.props.usersAndAssessmentsError || this.props.userError)
      return (
        <>
          <div className="dashboardContainer">
            <div className="row">
              <div className="col-3" />
              <div className="col-9">
                <ErrorMessage error={this.props.usersAndAssessmentsError || this.props.userError} />
              </div>
            </div>
          </div>
        </>
      );
    return (
      <div className="dashboardContainer">
        <div className="row">
          <div className="col-3">
            <div className="layer">
              <h2>Current Exclusions</h2>
            </div>

            <div className="layer">
              <p className="value">{this.state.excludedEmployees.length}</p>
            </div>

            <div className="layer">
              <h2>Today's Exclusions</h2>
            </div>

            <div className="layer excludeList">
              {!this.state.excludedEmployees ||
              this.state.excludedEmployees.length === 0 ? (
                <div>
                  <span className="exclusionName">No Exclusions</span>
                  <hr />
                </div>
              ) : (
                this.state.excludedEmployees.map((employee, i) => {
                  return (
                    <div key={i}>
                      <a href={`/#/assessmentLogs/${employee.ID}`} className="exclusionName">
                        {userDisplay(employee)} Store #{employee.storeNumber} {employee.store.regionalManager}
                      </a>
                      <hr />
                    </div>
                  );
                })
              )}
            </div>

            <div className="layer">
              <h2>Today's Restrictions</h2>
            </div>

            <div className="layer excludeList">
              {!this.state.restrictedEmployees ||
              this.state.restrictedEmployees.length === 0 ? (
                  <div>
                    <span className="exclusionName">No Restrictions</span>
                    <hr />
                  </div>
              ) : (
                  this.state.restrictedEmployees.map((employee, i) => {
                    return (
                        <div key={i}>
                          <a href={`/#/assessmentLogs/${employee.ID}`} className="exclusionName">
                            {userDisplay(employee)} Store #{employee.storeNumber} {employee.store.regionalManager}
                          </a>
                          <hr />
                        </div>
                    );
                  })
              )}
            </div>

            <div className="layer">
              <button onClick={this.openModal} className="manageAuthorization">
                Manage Authorizations
              </button>
            </div>
          </div>
          <div className="col-9">
            <div className="row noMargin">
              <div className="col-12">
                <div className="layer">
                  <h2>Exclusions By District</h2>
                  <p className="overview">
                    Total current exclusions by district.
                  </p>
                  <div className="barGraph">
                    <Bar
                      options={{
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true
                              }
                            }
                          ]
                        }
                      }}
                      data={this.state.data}
                      height={80}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row noMargin">
              <div className="col-7">
                <div>
                  <div className="layer regionOverview">
                    <h2>Regional Overview{this.state.selectedRegion !== undefined ? ` - ${this.props.regions[this.state.selectedRegion].regionalManager}` : ''}{this.state.selectedStore.id ? ` - Store ${this.state.selectedStore.storeNumber}` : ''}</h2>
                    <p className="overview">
                      Select a Region and a store to see its employees and their survey completion total.
                    </p>
                    <div className="flex">
                      { this.state.selectedRegion === undefined || isNaN(this.state.selectedRegion) ?
                          (this.props.regions || []).length &&
                          this.props.regions.map((region, i) => {
                            if(region.stores.length > 0){
                              return(
                                  <div key={i}>
                                    <span className="region" onClick={this.selectRegion(i)}>
                                      {region.regionalManager} Region
                                    </span>
                                  </div>
                              )
                            }
                        })
                        : !this.state.selectedStore.id ?
                          (this.props.regions[this.state.selectedRegion].stores || []).length &&
                          this.props.regions[this.state.selectedRegion].stores.map((store, i) => {
                            return(
                              <div key={i}>
                                <span className="region" onClick={this.selectStore(store)}>
                                  Store {store.storeNumber}
                                </span>
                              </div>
                            )
                          })
                        : (this.props.usersAndAssessmentsByStoreLoading) ?
                          <Loading/>
                        : (this.props.usersAndAssessmentsByStore || []).length && this.props.usersAndAssessmentsByStore.map((employee, i) => {
                          return (
                              <div key={i}>
                                <span className="regionEmployee">
                                  <a href={`/#/impersonate/${employee.employeeNumber}`}>{userDisplay(employee)}</a>
                                </span>{" "}
                                <span className="allCount">
                                  {employee.totalExcluded}
                                </span>
                              </div>
                            );
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div>
                  <div className="layer">
                    <h2>Geographic Overview</h2>
                    <Map data={this.state.mapData} cluster={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <table className="removeRestrictions">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Status</th>
                <th>Attestation</th>
              </tr>
            </thead>
            <tbody>
              {(this.state.excludedEmployees || []).length &&
                this.state.excludedEmployees.map((user: User, i: number) => {
                  return (
                    <tr key={i} className={"users"}>
                      <td>
                        <input
                          type="checkbox"
                          onClick={this.selectUser(user)}
                        />
                      </td>
                      <td className="name">
                        {userDisplay(user)}
                      </td>
                      <td className="state">{user.assessments[0].status}</td>
                      <td><textarea className="attestation" value={user.assessments[0].attestation} onChange={this.updateUserAttestation(user)} /></td>
                    </tr>
                  );
                })}
              <tr />
            </tbody>
          </table>
          <div className="buttonRow">
            <button
              type="button"
              onClick={() => {
                this.closeModal();
              }}
            >
              Cancel
            </button>
            <button
              disabled={this.validation()}
              onClick={this.submitRemoval}
              type="button"
            >
              Clear Status
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
