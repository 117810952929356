import React from "react";
import { Provider } from "react-redux";
import {
  HashRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { store } from "../store/store";
import { AppContainer } from "./appContainer";
import { LoginContainer } from "./login";
import { ChatContainer } from "./employee";
import {ClinicsListContainer} from "./clinics";
import {ClinicsEditContainer} from "./clinics/clinicEditContainer";
import {ClinicsDetailContainer} from "./clinics/clinicDetailContainer";
import {EmployeeProfileContainer} from "./employee/profile/employeeProfileContainer";
import {AssessmentLogEmployeeListContainer} from "./assessmentLogs/assessmentLogEmployeeListContainer";
import {AssessmentLogListContainer} from "./assessmentLogs/assessmentLogListContainer";
import {AdminDashboardContainer} from "./dashboards/adminDashboardContainer";
import {ReportsContainer} from "./reports/reportsContainer";
import {ManagerDashboardContainer} from "./dashboards/managerDashboardContainer";
import {ImpersonateContainer} from "./impersonate/impersonateContainer";
import {ClinicsMapContainer} from "./clinics/clinicMapContainer";

export const AppRouter: React.StatelessComponent<{}> = () => {
  return (
    <Provider store={store as any}>
      <Router>
        <div className="container-fluid">
          <Route component={AppContainer} />
          <Switch>
            <Route path="/" exact={true} component={LoginContainer} />
            <Route path="/employee" exact={true} component={ChatContainer} />
            <Route path="/profile/:id" exact={true} component={EmployeeProfileContainer} />
            <Route
              path="/adminDashboard"
              exact={true}
              component={AdminDashboardContainer}
            />
            <Route
              path="/managerDashboard"
              exact={true}
              component={ManagerDashboardContainer}
            />
            <Route
              path="/assessmentLogs"
              exact={true}
              component={AssessmentLogEmployeeListContainer}
            />
            <Route
              path="/assessmentLogs/:id"
              exact={true}
              component={AssessmentLogListContainer}
            />
            <Route
                path="/clinics"
                exact={true}
                component={ClinicsListContainer}
            />
            <Route
                path="/clinics/map"
                exact={true}
                component={ClinicsMapContainer}
            />
            <Route
                path="/clinic/:id"
                exact={true}
                component={ClinicsDetailContainer}
            />
            <Route
                path="/clinic/edit/:id"
                exact={true}
                component={ClinicsEditContainer}
            />
            <Route
                path="/reports"
                exact={true}
                component={ReportsContainer}
            />
            <Route
                path="/impersonate/:id"
                exact={true}
                component={ImpersonateContainer}
            />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};
