import React, { Fragment, useEffect, useRef } from "react";
import { Conversation, ConversationItem } from "../../../models/conversation";
import { HcAnswer } from "./HcAnswer";
import { HcQuestion } from "./HcQuestion";
import { HcFinalAssessment } from "./HcFinalAssessment";
import { HcConversationImage } from "./HcConversationImage";
type Props = {
  conversation: Conversation;
  shouldScrollToBottom: boolean;
};
/*https://stackoverflow.com/questions/37620694/how-to-scroll-to-bottom-in-react*/
export const HcPreviousConversation = ({ conversation: chat, shouldScrollToBottom: should }: Props) => {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current && should)
      (messagesEndRef.current as any).scrollIntoView({ behavior: "auto" });
      
  };

  useEffect(scrollToBottom, [chat]);
  return (
    <Fragment key={`HcPreviousConversation-${chat.id}`}>
      <div className="col conversation">
        {!!(chat.items || []).length &&
          chat.items.map((c: ConversationItem, i: number) => {
            if (c.answer) {
              return (
                <Fragment key={`HcConversationAnswer${chat.id}${i}`}>
                  <HcAnswer item={c} />
                </Fragment>
              );
            } else if (c.question) {
              return (
                <Fragment key={`HcConversationAnswer${chat.id}${i}`}>
                  <HcQuestion item={c} />
                </Fragment>
              );
            } else if (c.imageUrl) {
              return (
                <Fragment key={`HcConversationAnswer${chat.id}${i}`}>
                  <HcConversationImage item={c} />
                </Fragment>
              );
            }
          })}
        <HcFinalAssessment
          {...{
            status: chat.finalStatus ? chat.finalStatus.replace(/_/g, ' '): chat.finalStatus,
            text: chat.finalText,
            statusId: chat.finalStatusId
          }}
        />
        <div ref={messagesEndRef} />
      </div>
    </Fragment>
  );
};
