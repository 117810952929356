import axios from "axios";
import { actionTypes } from "../common/constants";
import { AppConstants } from "../common/constants";
import { User } from "../models";
const client = axios.create({ baseURL: AppConstants.serverUrl });

export const login = () => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.LOGIN_LOADING });
  client.get(`/login`).then(
    (response: any) => {
      dispatch(loginCompleted(response.data));
    },
    error => {
      dispatch(loginFailed(error));
    }
  );
};

const loginCompleted = (profile: any) => ({
  payload: profile,
  type: actionTypes.LOGIN_COMPLETED
});

const loginFailed = (error: any) => ({
  payload: error,
  type: actionTypes.LOGIN_FAILED
});

export const getProfileByUser = (id: number, isMobile: boolean) => (
  dispatch: any
) => {
  dispatch({ payload: {}, type: actionTypes.GET_PROFILE_BY_USER_LOADING });
  client.get(`/api/users/getProfileByUser?id=${id}`).then(
    (response: any) => {
      dispatch(fetchProfileCompleted({ ...response.data, isMobile } as User));
    },
    error => {
      dispatch(fetchProfileFailed(error));
    }
  );
};

const fetchProfileCompleted = (profile: User) => ({
  payload: profile,
  type: actionTypes.GET_PROFILE_BY_USER_COMPLETED
});

const fetchProfileFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_PROFILE_BY_USER_FAILED
});

export const updateMobileLayout = (user: User, isMobile: boolean) => (
  dispatch: any
) => {
  dispatch(updateMobileLayoutCompleted({ ...user, isMobile }));
};
const updateMobileLayoutCompleted = (user: User) => ({
  payload: user,
  type: actionTypes.UPDATE_MOBILE_LAYOUT
});

export const getAllUsers = () => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.GET_USERS_LOADING });
  client.get(`/api/users/getAllUsers`).then(
    (response: any) => {
      dispatch(fetchUsersCompleted(response.data));
    },
    error => {
      dispatch(fetchUsersFailed(error));
    }
  );
};

const fetchUsersCompleted = (users: any) => ({
  payload: users,
  type: actionTypes.GET_USERS_COMPLETED
});

const fetchUsersFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_USERS_FAILED
});

export const updateUserProfile = (user: User) => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.UPDATE_USER_PROFILE_LOADING });
  client.post(`/api/users/updateUserProfile`, user).then(
    (response: any) => {
      dispatch(updateUserProfileCompleted(response.data));
    },
    error => {
      dispatch(updateUserProfileFailed(error));
    }
  );
};

const updateUserProfileCompleted = (response: any) => ({
  payload: response,
  type: actionTypes.UPDATE_USER_PROFILE_COMPLETED
});

const updateUserProfileFailed = (error: any) => ({
  payload: error,
  type: actionTypes.UPDATE_USER_PROFILE_FAILED
});
