import React from "react";
type Props = {
  label?: string;
  [key: string]: any;
};
export const HcFormGroup = (props: Props) => {
  return (
    <>
      <div className="hc form-group">
        {props.label ? <label>{props.label}</label> : null}
        {props.children}
      </div>
    </>
  );
};
