import { connect } from 'react-redux';
import { ReduxState } from '../../store/reducers';
import { Reports } from './reports';
import {getExportData, getReportData} from "../../actions/reportsAction";

const mapStateToProps = (state: ReduxState) => ({
    reportData: state.reportData.data,
    reportDataLoading: state.reportData.loading,
    reportDataError: state.reportData.error,

    exportData: state.exportData.data,
    exportDataLoading: state.exportData.loading,
    exportDataError: state.exportData.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchReportData: () => dispatch(getReportData()),
    fetchExportData: () => dispatch(getExportData())
});

export const ReportsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Reports);