import * as React from "react";
import { Clinic } from "../../models";
import { Map } from "../map/map";
import { ErrorMessage } from "../shared/ErrorMessage";
import { Loading } from "../shared/Loading";

interface Props {
  location: any;
  history: any;
  clinic: Clinic;
  clinicLoading: boolean;
  clinicError: string;

  fetchClinic(id: number): void;
  deleteClinic(clinic: Clinic): void;
}

export class ClinicDetail extends React.Component<Props, { clinic: any }> {
  constructor(props: any) {
    super(props);

    this.state = {
      clinic: this.props.clinic,
    };

    this.cancel = this.cancel.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    const pathId = this.props.location.pathname
      .toString()
      .replace("/clinic/", "");

    this.props.fetchClinic(pathId);
  }

  cancel() {
    this.props.history.push("/clinics");
  }

  edit() {
    this.props.history.push(`/clinic/edit/${this.props.clinic.ID}`);
  }

  delete() {
    this.props.deleteClinic(this.props.clinic);
    setTimeout(() => {
      this.props.history.push("/clinics");
    }, 1000);
  }

  render() {
    if (this.props.clinicLoading) return <Loading />;
    if (this.props.clinicError)
      return <ErrorMessage error={this.props.clinicError} />;

    return (
      <div className="clinicsContainer">
        <h2>Clinic Database</h2>
        <div className="row">
          <div className="col-4">
            <div className="clinicInformation">
              <h3>{this.props.clinic.clinicName}</h3>
              <p>
                {this.props.clinic.address}
                <br />
                {this.props.clinic.city}, {this.props.clinic.state}{" "}
                {this.props.clinic.zipcode}
              </p>

              {this.props.clinic.email ? (
                <a href={"mailto:" + this.props.clinic.email} className="email">
                  {this.props.clinic.email}
                </a>
              ) : (
                ""
              )}

              <br />
              <br />

              <h3>Clinic Staff</h3>
              <p>{this.props.clinic.staff}</p>

              <br />

              <h3>Phone Number</h3>
              <p>{this.props.clinic.phoneNumber}</p>

              <br />

              <h3>Stores Served</h3>
              <p>#1</p>
            </div>
          </div>
          <div className="col-8">
            {!!this.props.clinic.clinicName ? (
              <Map data={[this.props.clinic]} cluster={false} />
            ) : (
              ""
            )}
            <br />
            <div className="mapKey">
              <i className="mapKeyClinic"></i>
              <span>Active Clinic</span> <i className="mapKeyStore"></i>
              <span>EG America Location</span>
            </div>
          </div>
        </div>

        <div className="buttonRow">
          <button onClick={this.edit} className="edit">
            Edit Information
          </button>
          <button onClick={this.delete} className="delete">
            Delete Clinic
          </button>
        </div>
      </div>
    );
  }
}
