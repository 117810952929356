import React from "react";
type Props = {
    status: string;
}
export const RestrictedPrompt = (props: Props) => {
    const { status } = props;
  return (
    <div className="chatBot">
      <div className="emptyAssessment">
        <p className="startAssessmentText">{!status.includes('CLEARED') ? status.split('.')[0] : status}</p>
        <p className="selectAssessmentText">Unable to complete a new assessment</p>
      </div>
    </div>
  );
};
