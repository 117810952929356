import React, {Component} from "react";
import {Store, User} from "../../../../models";
import {ChatState as State, DefaultChatComponentState as DefaultState} from "../../helpers";

type Props = {
  stores: Store[];
  cancelFirstTime: Function;
  cancelFirstTimeAndStartAssessment: Function;
};
export class Welcome extends Component<Props, {user:User}> {
    constructor(props: any) {
        super(props);
        this.state = {
            user: {} as User
        };
    }

    handleChange = (event: any) => {
        const user: any = this.state.user;
        if(event && event.target.id === 'phoneNumber'){
            user.phoneNumber = event.target.value;
            this.setState({user: user});
        }else if(event && event.target.id === 'languagePreference'){
            user.languagePreference = event.target.value;
            this.setState({user: user});
        }else if(event && event.target.id === 'storeNumber'){
            user.storeNumber = event.target.value;
            this.setState({user: user});
        }
    }

    cancelFirstTimeAndStartAssessment = () => {
        this.props.cancelFirstTimeAndStartAssessment(this.state.user);
    }

    render() {
        return (
            <div className="chatBot welcomeContainer">
                <img
                    className="close"
                    onClick={e => {
                        e.preventDefault();
                        this.props.cancelFirstTime();
                    }}
                />
                <h1 className="welcome">Welcome!</h1>
                <p className="title">
                    Please complete this short assessment to determine if you are eligible to work.
                </p>
                <p className="content">
                    HealthCheck is a friendly chat service that asks you to answer a few simple questions about your recent health conditions. This service evaluates your current condition based on your answers in the chat conversation and returns one of three decisions:
                </p>

                <span className="iconExample">
        <span className="pass" />
        No restrictions. Employee can report to work as usual.
      </span>

                <span className="iconExample">
        <span className="moderate" />
        Restricted. You can report to work as usual, but you must abide by the specified restrictions.
      </span>

                <span className="iconExample">
        <span className="fail" />
        Excluded. Do not report to work, but you should make an appointment with a nearby clinic.
      </span>

                <p className="content">
                    In order to be eligible to work, you must complete the full survey. You will know when the survey completes when the bot replies with one of the three outcomes above. Please answer all questions, completely, honestly and to the best of your ability. After completing the three profile questions below, click the Start a New Assessment button to begin your assessment.
                </p>

                <div className="row">
                    <div className="col-3">
                        <h2>Language</h2>
                        <select onChange={this.handleChange} id="languagePreference">
                            <option>Select a Language</option>
                            <option value="english">English</option>
                            <option value="spanish">Spanish/Espanol</option>
                        </select>
                    </div>
                    <div className="col-3">
                        <h2>Phone Number</h2>
                        <input onChange={this.handleChange} id="phoneNumber" className="phoneNumber" />
                        <small>Data charges may apply.</small>
                    </div>
                    {/*<div className="col-4">*/}
                    {/*    <h2>Store</h2>*/}
                    {/*    <select onChange={this.handleChange} id="storeNumber" className="storeNumber" >*/}
                    {/*        <option>Select a Store</option>*/}
                    {/*        {this.props.stores.map((store, i) => {*/}
                    {/*            return <option value={store.storeNumber} key={i}> {store.storeNumber} - {store.address}</option>*/}
                    {/*        })}*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                </div>

                <button
                    disabled={!this.state.user.phoneNumber || !this.state.user.languagePreference}
                    onClick={e => {
                        e.preventDefault();
                        this.cancelFirstTimeAndStartAssessment();
                    }}
                    className="startAssessmentButton"
                >
                    Save and Start A New Assessment
                </button>
            </div>
        );
    }
};
