import * as React from "react";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { User } from "../../models";
import { Loading } from "../shared/Loading";
import { ErrorMessage } from "../shared/ErrorMessage";
import {userDisplay} from "../../common/util";

interface Props {
  user: User;
  userLoading: boolean;
  userError: string;

  usersAndAssessmentsByStore: User[];
  usersAndAssessmentsByStoreLoading: boolean;
  usersAndAssessmentsByStoreError: string;

  fetchAllUsersAndAssessmentsByStore(storeId: string): void;
}

export class ManagerDashboard extends React.Component<
  Props,
  { excludedEmployees: any[]; data: any; totalExclusions: number }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      totalExclusions: 0,
      excludedEmployees: [],
      data: {
        labels: [],
        datasets: [
          {
            label: "Exclusions Per Day",
            backgroundColor: "rgba(119,178,0, 0.8)",
            borderColor: "rgba(119,178,0, 0.8)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(119,178,0, 0.8)",
            hoverBorderColor: "rgba(119,178,0, 0.8)",
            data: []
          }
        ]
      }
    };
  }

  updateData() {
    let totalExclusions: number = 0;
    let excludedEmployees: any[] = [];
    let assessmentsByDay: any[] = [];
    let dates = this.getDateArray(
      moment()
        .subtract(30, "days")
        .toDate(),
      new Date()
    );

    for (const user of this.props.usersAndAssessmentsByStore) {
      for (const assessment of user.assessments) {
        if (assessment.status && assessment.status != "GOOD") {
          totalExclusions++;
          assessmentsByDay[new Date(assessment.date).getDate()]
            ? (assessmentsByDay[new Date(assessment.date).getDate()] += 1)
            : (assessmentsByDay[new Date(assessment.date).getDate()] = 1);
        }
      }

      if (user.currentlyExcluded && user.currentlyExcluded > 0) {
        excludedEmployees.push(user);
      }
    }

    let graphData: any[] = [];
    let data = this.state.data;
    for (const date of dates) {
      graphData.push(
        assessmentsByDay[new Date(date).getDate()]
          ? assessmentsByDay[new Date(date).getDate()]
          : 0
      );
    }

    data.labels = dates;
    data.datasets[0].data = graphData;
    this.setState({
      excludedEmployees: excludedEmployees,
      data: data,
      totalExclusions: totalExclusions
    });
  }

  // date array
  getDateArray(start: Date, end: Date) {
    let arr = new Array(),
      dt = new Date(start);

    while (dt <= end) {
      arr.push(new Date(dt).toLocaleDateString());
      dt.setDate(dt.getDate() + 1);
    }

    return arr;
  }

  componentDidMount() {
    if(!this.props.user.ID && !this.props.userLoading){
      window.location.href = '/';
    }
    
    if (this.props.user.storeNumber)
      this.props.fetchAllUsersAndAssessmentsByStore(
        this.props.user.storeNumber
      );
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.user !== prevProps.user && this.props.user.storeNumber) {
      this.props.fetchAllUsersAndAssessmentsByStore(
        this.props.user.storeNumber
      );
    }

    if (
      this.props.usersAndAssessmentsByStore !==
      prevProps.usersAndAssessmentsByStore
    ) {
      this.updateData();
    }
  }

  render() {
    const loading =
      this.props.usersAndAssessmentsByStoreLoading || this.props.userLoading;
    const error =
      this.props.usersAndAssessmentsByStoreError || this.props.userError;

    let totalExclusions = +this.state.totalExclusions || 0;
    let empCount = +(this.props.usersAndAssessmentsByStore || []).length || 0;
    let exclusionsPerEmployee = totalExclusions / empCount;
    exclusionsPerEmployee = isNaN(exclusionsPerEmployee)
      ? 0
      : exclusionsPerEmployee;

    if(loading){
      return <Loading/>
    }

    return (
      <div className="dashboardContainer">
        <h1>Store {this.props.user.storeNumber}</h1>
        <div className="row">
          { error ? (
            <ErrorMessage error={error} />
          ) : (
            <>
              <div className="col-3">
                <div className="layer">
                  <h2>Today's Exclusions</h2>
                </div>

                <div className="layer">
                  {!this.state.excludedEmployees ||
                  this.state.excludedEmployees.length === 0 ? (
                    <div>
                      <span className="exclusionName">No Exclusions</span>
                      <hr />
                    </div>
                  ) : (
                    this.state.excludedEmployees.map((employee, i) => {
                      return (
                        <div key={i}>
                          <span className="exclusionName">
                            {userDisplay(employee)}
                          </span>
                          <hr />
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              <div className="col-9">
                <div className="col-4">
                  <div className="layer blueBorder">
                    <h2>Exclusions Past 30 Days</h2>
                    <p className="value">{totalExclusions}</p>
                    {/*<p className="average">Nationwide Average 15</p>*/}
                  </div>
                </div>
                <div className="col-4">
                  <div className="layer blueBorder">
                    <h2>Exclusions Per Employee</h2>
                    <p className="value">{exclusionsPerEmployee.toFixed(3)}</p>
                    {/*<p className="average">Nationwide Average 0.5</p>*/}
                  </div>
                </div>
                <div className="col-4">
                  <div className="layer blueBorder">
                    <h2>30 Day High YTD</h2>
                    <p className="value">{totalExclusions}</p>
                    {/*<p className="average">Nationwide Average 18</p>*/}
                  </div>
                </div>

                <div>
                  <div className="layer">
                    <h2>Last 30 Days</h2>
                    <div className="barGraph">
                      <Bar
                        options={{
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true
                                }
                              }
                            ]
                          }
                        }}
                        data={this.state.data}
                        height={80}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="layer">
                    <h2>Employee Overview</h2>
                    <p className="overview">
                      Select an employee to impersonate.
                    </p>
                    <div className="flex">
                      {(this.props.usersAndAssessmentsByStore || []).length &&
                        this.props.usersAndAssessmentsByStore.map(
                          (employee, i) => {
                            return (
                              <div key={i}>
                                <span className="allName">
                                  <a href={`/#/impersonate/${employee.employeeNumber}`}>{userDisplay(employee)}</a>
                                </span>{" "}
                                <span className="allCount">
                                  {employee.totalExcluded}
                                </span>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
