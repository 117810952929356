import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

type Props = {
  updateMethod: Function;
  name: string;
  value: Date | undefined;
  placeholder: string;
  showTimeSelect?: boolean;
  [key: string]: any;
};

export const HcDatePicker = ({
  updateMethod,
  name,
  value,
  placeholder: title,
  showTimeSelect = false,
  ...rest
}: Props) => {
  const valueMoment = value ? moment(value) : null;
  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        <DatePicker
          placeholderText={title}
          popperPlacement="bottom"
          showTimeSelect={showTimeSelect}
          dateFormat="LLL"
          selected={valueMoment as any}
          onChange={(val: any) => {
            if (val !== null) val = (val as moment.Moment).toDate();
            updateMethod(name, val);
          }}
          {...rest}
        />
      </div>
    </>
  );
};
