import React, { Fragment } from "react";
import {
  completedText,
  truncateText,
  getIconClass,
  formatDateForDisplay
} from "../../../common/util";
import { Assessment } from "../../../models";
type HcAssessmentItemProps = {
  assessment: Assessment;
  index: number;
  selected: boolean;
};
export const HcAssessmentItem = ({
  assessment,
  index,
  selected
}: HcAssessmentItemProps) => {
  if (assessment.id) {
    const x = assessment as Assessment;
    return (
      <Fragment key={`HcAssessmentItem${x.id}${index}`}>
        <div className={`chat ${selected ? "selected" : ""}`}>
          <div className="row">
            <div className={"chatLight col-2"}>
              <span className={getIconClass(x.statusId, (x.completed == 1 ? true : false))} />
            </div>

            <div className="col-7">
              <span className="title">{x.status.replace(/_/g, ' ')}</span>
              <span className="body" title={x.description}>
                {x.completed == 1 && x.completedDate ? completedText(x.completedDate) : truncateText(x.description, 45)}
              </span>
            </div>

            <div className="col-3 date">{formatDateForDisplay(x.date)}</div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <div className={`chat ${selected ? "selected" : ""}`}>
        <div className="row">
          <div className={"chatLight col-2"}>
            <span className={getIconClass(undefined)} />
          </div>

          <div className="col-7">
            <span className="title">No prior assessments</span>
            <span className="body" />
          </div>

          <div className="col-3 date" />
        </div>
      </div>
    );
  }
};
