import React from "react";
import { HcSubmitButton } from "../../../shared/forms/HcSubmitButton";
import { HcClearButton } from "../../../shared/forms/HcClearButton";
import { HcDatePicker } from "../../../shared/forms/HcDatePicker";
import { HcFormGroup } from "../../../shared/forms/HcFormGroup";
import {
  HcMultiSelectList,
  HcOption
} from "../../../shared/forms/HcMultiSelectList";

type Props = {
  update(key: string, val: any): void;
  updateOutcomes(selectedStatusIds: number[]): void;
  startDate: Date | undefined;
  endDate: Date | undefined;
  statusIds: number[];
  statusOptions: HcOption[];
  submit: Function;
  valid: Function;
  clear: Function;
};

export const FilterAssessments = (props: Props) => {
  const {
    update,
    updateOutcomes,
    submit,
    clear,
    valid,
    startDate,
    endDate,
    statusIds,
    statusOptions
  } = props;
  const isValid = valid();
  return (
    <>
      <div className="filter-pane">
        <HcFormGroup label="Date Range">
          <HcDatePicker
            {...{
              name: "startDate",
              placeholder: "Select Beginning Date",
              updateMethod: update,
              value: startDate,
              style: { marginBottom: 15 }
            }}
          />
          <HcDatePicker
            {...{
              name: "endDate",
              placeholder: "Select Ending Date",
              updateMethod: update,
              value: endDate
            }}
          />
        </HcFormGroup>
        <HcFormGroup label="Outcomes">
          <HcMultiSelectList
            options={statusOptions}
            name="statusIds"
            value={statusIds}
            updateMethod={updateOutcomes}
            placeholder="Select One or More Outcomes"
          />
        </HcFormGroup>
        <HcFormGroup>
          <HcSubmitButton {...{ isValid, submit, text: "Apply" }} />
          <HcClearButton {...{ clear }} />
        </HcFormGroup>
      </div>
    </>
  );
};
