import { connect } from "react-redux";
import { ReduxState } from "../../store/reducers";
import {
  getChatData,
  endOfConversation
} from "../employee/actions";
import { ImpersonateComponent } from "./impersonate";

const mapStateToProps = (state: ReduxState) => ({
  chatData: state.chatData.data,
  chatDataError: state.chatData.error,
  chatDataLoading: state.chatData.loading,

  user: state.user.data,
  userError: state.user.error,
  userLoading: state.user.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getChatData: (id: string) => dispatch(getChatData(id)),
  endOfConversation: (id: string, chatId: string, adminId: number) =>
    dispatch(endOfConversation(id, chatId, adminId))
});

export const ImpersonateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpersonateComponent);
