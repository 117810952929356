export const AppConstants = {
  mapKey: "RenyejBwMxSlrqifqDMYxbvBP0H5nkJf0SHo6XA27Ik",
  geocodeUrl: "https://atlas.microsoft.com/search/address/json?subscription-key=RenyejBwMxSlrqifqDMYxbvBP0H5nkJf0SHo6XA27Ik&api-version=1.0&query=",
  loginUrl: window.location.origin.includes("test") ? "https://healthcheck-test.azurewebsites.net/login" : "https://healthcheck.cumberlandfarms.com/login",
  serverUrl: window.location.origin.includes("http://localhost")
    ? "https://healthcheck.cumberlandfarms.com" :
    window.location.origin.includes("test") ? "https://healthcheck-test.azurewebsites.net" : "https://healthcheck.cumberlandfarms.com"
};

export const UIDefaults = {
  blankChar: "—",
  desktopWidth: 1107,
  error: "Something went wrong. Please try again."
}

export const actionTypes = {
  LOGIN_COMPLETED: 'LOGIN_COMPLETED',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_LOADING: 'LOGIN_LOADING',

  CREATE_CLINIC_COMPLETED: 'CREATE_CLINIC_COMPLETED',
  CREATE_CLINIC_FAILED: 'CREATE_CLINIC_FAILED',
  CREATE_CLINIC_LOADING: 'CREATE_CLINIC_LOADING',
  DELETE_CLINIC_COMPLETED: 'DELETE_CLINIC_COMPLETED',
  DELETE_CLINIC_FAILED: 'DELETE_CLINIC_FAILED',
  DELETE_CLINIC_LOADING: 'DELETE_CLINIC_LOADING',
  GET_ALL_CLINICS_COMPLETED: 'GET_ALL_CLINICS_COMPLETED',
  GET_ALL_CLINICS_FAILED: 'GET_ALL_CLINICS_FAILED',
  GET_ALL_CLINICS_LOADING: 'GET_ALL_CLINICS_LOADING',
  GET_CLINIC_COMPLETED: 'GET_CLINIC_COMPLETED',
  GET_CLINIC_FAILED: 'GET_CLINIC_FAILED',
  GET_CLINIC_LOADING: 'GET_CLINIC_LOADING',
  UPDATE_CLINIC_COMPLETED: 'UPDATE_CLINIC_COMPLETED',
  UPDATE_CLINIC_FAILED: 'UPDATE_CLINIC_FAILED',
  UPDATE_CLINIC_LOADING: 'UPDATE_CLINIC_LOADING',

  GET_PROFILE_BY_USER_COMPLETED: 'GET_PROFILE_BY_USER_COMPLETED',
  GET_PROFILE_BY_USER_FAILED: 'GET_PROFILE_BY_USER_FAILED',
  GET_PROFILE_BY_USER_LOADING: 'GET_PROFILE_BY_USER_LOADING',
  GET_USERS_COMPLETED: 'GET_USERS_COMPLETED',
  GET_USERS_FAILED: 'GET_USERS_FAILED',
  GET_USERS_LOADING: 'GET_USERS_LOADING',
  UPDATE_USER_PROFILE_COMPLETED: 'UPDATE_USER_PROFILE_COMPLETED',
  UPDATE_USER_PROFILE_FAILED: 'UPDATE_USER_PROFILE_FAILED',
  UPDATE_USER_PROFILE_LOADING: 'UPDATE_USER_PROFILE_LOADING',
  UPDATE_MOBILE_LAYOUT: 'UPDATE_MOBILE_LAYOUT',

  GET_ALL_ASSESSMENTS_BY_USER_COMPLETED:'GET_ALL_ASSESSMENTS_BY_USER_COMPLETED',
  GET_ALL_ASSESSMENTS_BY_USER_FAILED:'GET_ALL_ASSESSMENTS_BY_USER_FAILED',
  GET_ALL_ASSESSMENTS_BY_USER_LOADING:'GET_ALL_ASSESSMENTS_BY_USER_LOADING',
  GET_ALL_USERS_WITH_ASSESSMENTS_COMPLETED:'GET_ALL_USERS_WITH_ASSESSMENTS_COMPLETED',
  GET_ALL_USERS_WITH_ASSESSMENTS_FAILED:'GET_ALL_USERS_WITH_ASSESSMENTS_FAILED',
  GET_ALL_USERS_WITH_ASSESSMENTS_LOADING:'GET_ALL_USERS_WITH_ASSESSMENTS_LOADING',
  GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_COMPLETED:'GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_COMPLETED',
  GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_FAILED: 'GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_FAILED',
  GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_LOADING: 'GET_ALL_USERS_WITH_ASSESSMENTS_BY_STORE_LOADING',
  GET_ASSESSMENT_BY_ID_COMPLETED:'GET_ASSESSMENT_BY_ID_COMPLETED',
  GET_ASSESSMENT_BY_ID_FAILED: 'GET_ASSESSMENT_BY_ID_FAILED',
  GET_ASSESSMENT_BY_ID_LOADING: 'GET_ASSESSMENT_BY_ID_LOADING',
  GET_ASSESSMENTS_BY_STORE_COMPLETED: 'GET_ASSESSMENTS_BY_STORE_COMPLETED',
  GET_ASSESSMENTS_BY_STORE_FAILED: 'GET_ASSESSMENTS_BY_STORE_FAILED',
  GET_ASSESSMENTS_BY_STORE_LOADING: 'GET_ASSESSMENTS_BY_STORE_LOADING',
  REMOVE_RESTRICTION_COMPLETED: 'REMOVE_RESTRICTION_COMPLETED',
  REMOVE_RESTRICTION_FAILED: 'REMOVE_RESTRICTION_FAILED',
  REMOVE_RESTRICTION_LOADING: 'REMOVE_RESTRICTION_LOADING',

  GET_REPORT_DATA_COMPLETED: 'GET_REPORT_DATA_COMPLETED',
  GET_REPORT_DATA_FAILED: 'GET_REPORT_DATA_FAILED',
  GET_REPORT_DATA_LOADING: 'GET_REPORT_DATA_LOADING',
  GET_EXPORT_DATA_COMPLETED: 'GET_EXPORT_DATA_COMPLETED',
  GET_EXPORT_DATA_FAILED: 'GET_EXPORT_DATA_FAILED',
  GET_EXPORT_DATA_LOADING: 'GET_EXPORT_DATA_LOADING',

  GET_STORES_COMPLETED: 'GET_STORES_COMPLETED',
  GET_STORES_FAILED: 'GET_STORES_FAILED',
  GET_STORES_LOADING: 'GET_STORES_LOADING',

  GET_REGIONS_COMPLETED: 'GET_REGIONS_COMPLETED',
  GET_REGIONS_FAILED: 'GET_REGIONS_FAILED',
  GET_REGIONS_LOADING: 'GET_REGIONS_LOADING'
};
