import React from "react";
import { removeAuth } from "../security/auth";

import AuthHeader from "./header/header";
import { User } from "../models";
import { UIDefaults } from "../common/constants";
import { Loading } from "./shared/Loading";
import { ErrorMessage } from "./shared/ErrorMessage";

interface Props {
  location: any;
  user: User;
  userLoading: boolean;
  userError: string;
  fetchUserProfile(id: number, isMobile: boolean): void;
  updateMobileLayout(user: User, isMobile: boolean): void;
}
export class App extends React.Component<
  Props,
  { loggedIn: boolean; user: User; userId: number | null }
> {
  constructor(props: any) {
    super(props);
    let loggedIn = false;
    let userId = this.props.location.search.toString().replace("?id=", "");
    if (userId && !isNaN(parseInt(userId))) {
      window.localStorage.setItem("userId", userId);
      loggedIn = true;
    } else {
      userId = window.localStorage.getItem("userId");
      if (userId && !isNaN(parseInt(userId))) {
        loggedIn = true;
      }
    }

    console.log(loggedIn, this.props.user, userId);

    this.state = {
      loggedIn: loggedIn,
      user: this.props.user,
      userId: userId
    };

    this.logout = this.logout.bind(this);
  }

  componentDidMount(): void {
    const userId = window.localStorage.getItem("userId");
    if (userId && !isNaN(parseInt(userId))) {
      this.props.fetchUserProfile(parseInt(userId), !!this.handleResize(true));
      this.setState({ loggedIn: true });
    }else {
      window.location.href = '/';
    }
    window.addEventListener("resize", () => this.handleResize());
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.user) {
      this.setState({ user: nextProps.user });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.handleResize());
  }

  handleResize(isInitial: boolean = false) {
    const isMobile = window.innerWidth < UIDefaults.desktopWidth;
    if (isInitial) return isMobile;
    if (this.state.user) {
      if (this.state.user.isMobile != isMobile) {
        this.props.updateMobileLayout(this.state.user, isMobile);
      }
    }
  }

  logout() {
    removeAuth();
    this.setState({ loggedIn: false });
  }

  render() {
    return (
      <div className="app">
        {this.props.userLoading ? (
          <Loading />
        ) : this.props.userError ? (
          <ErrorMessage error={this.props.userError} />
        ) : (
          <AuthHeader user={this.props.user} />
        )}
      </div>
    );
  }
}
