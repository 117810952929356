import * as React from "react";
import { Store, User } from "../../../models";
import { UIDefaults } from "../../../common/constants";
import { HcSubmitButton } from "../../shared/forms/HcSubmitButton";
import { HcFormGroup } from "../../shared/forms/HcFormGroup";
import { HcClearButton } from "../../shared/forms/HcClearButton";
import { ErrorMessage } from "../../shared/ErrorMessage";
import { Loading } from "../../shared/Loading";

interface Props {
  location: any;
  user: User;
  userLoading: boolean;
  userError: string;

  stores: Store[];
  storesLoading: boolean;
  storesError: string;
  
  fetchUserProfile(id: number, isMobile: boolean): void;
  updateUserProfile(user: User): void;
  fetchStores(): void;
}

export class EmployeeProfile extends React.Component<Props, { user: User }> {
  constructor(props: any) {
    super(props);

    this.state = { user: this.props.user };

    this.clear = this.clear.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const userId = window.localStorage.getItem("userId") || 0;
    this.props.fetchUserProfile(
      +userId,
      window.innerWidth < UIDefaults.desktopWidth
    );
    this.props.fetchStores();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.user) {
      this.setState({ user: nextProps.user });
    }
  }

  clear() {
    let user = this.props.user;
    user.languagePreference = "";
    user.phoneNumber = "";
    // user.storeNumber = "";
    this.setState({ user: user });
  }

  save() {
    this.props.updateUserProfile(this.state.user);
  }

  handleChange(event: any) {
    const user: User = this.state.user;
    if (event.target.id === "phoneNumber") {
      user.phoneNumber = event.target.value;
    } else if (event.target.id === "languagePreference") {
      user.languagePreference = event.target.value;
    }
    // else if (event.target.id === "storeNumber") {
    //   user.storeNumber = event.target.value;
    // }

    this.setState({ user: user });
  }

  render() {
    const error = this.props.storesError || this.props.userError;
    const loading = this.props.storesLoading || this.props.userLoading;
    if (loading) return <Loading />;
    if (error) return <ErrorMessage error={error} />;
    if (!this.props.user.isMobile)
      return (
        <div className="profileContainer">
          <h1>
            Settings - {this.props.user.firstName} {this.props.user.lastName}
          </h1>
          <div className="row">
            <div className="col-4">
              <h2>Language Preference</h2>
              <select
                onChange={this.handleChange}
                id="languagePreference"
                value={this.state.user.languagePreference}
              >
                <option>Select a Language</option>
                <option value="english">English</option>
                <option value="spanish">Spanish/Espanol</option>
              </select>
            </div>
            <div className="col-4">
              <h2>Phone Number</h2>
              <input
                onChange={this.handleChange}
                id="phoneNumber"
                value={this.state.user.phoneNumber}
                className="phoneNumber"
              />
              <small>Data charges may apply.</small>
            </div>

            {/*<div className="col-4">*/}
            {/*  <h2>Store</h2>*/}
            {/*  <select*/}
            {/*    onChange={this.handleChange}*/}
            {/*    id="storeNumber"*/}
            {/*    value={this.state.user.storeNumber}*/}
            {/*    className="storeNumber"*/}
            {/*  >*/}
            {/*    <option>Select a Store</option>*/}
            {/*    {this.props.stores &&*/}
            {/*      this.props.stores.map((store, i) => {*/}
            {/*        return (*/}
            {/*          <option value={store.storeNumber} key={i}>*/}
            {/*            {" "}*/}
            {/*            {store.storeNumber} - {store.address}*/}
            {/*          </option>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*  </select>*/}
            {/*</div>*/}
          </div>
          <HcFormGroup>
            <div className="row">
              <div className="col-3" />
              <div className="col-3">
                <HcClearButton clear={() => this.clear()} text="Clear" />
              </div>
              <div className="col-3">
                <HcSubmitButton
                  submit={() => this.save()}
                  text="Apply"
                  isValid={true}
                />
              </div>
              <div className="col-3" />
            </div>
          </HcFormGroup>
        </div>
      );
    else
      return (
        <div className="profileContainer">
          <h1>
            Settings - {this.props.user.firstName} {this.props.user.lastName}
          </h1>
          <div className="row">
            <div className="col-4">
              <h2>Language Preference</h2>
              <select
                onChange={this.handleChange}
                id="languagePreference"
                value={this.state.user.languagePreference}
              >
                <option>Select a Language</option>
                <option value="english">English</option>
                <option value="spanish">Spanish/Espanol</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <h2>Phone Number</h2>
              <input
                onChange={this.handleChange}
                id="phoneNumber"
                value={this.state.user.phoneNumber}
                className="phoneNumber"
              />
              <small>Data charges may apply.</small>
            </div>
          </div>
          {/*<div className="row">*/}
          {/*  <div className="col-4">*/}
          {/*    <h2>Store</h2>*/}
          {/*    <select*/}
          {/*      onChange={this.handleChange}*/}
          {/*      id="storeNumber"*/}
          {/*      value={this.state.user.storeNumber}*/}
          {/*      className="storeNumber"*/}
          {/*    >*/}
          {/*      <option>Select a Store</option>*/}
          {/*      {this.props.stores &&*/}
          {/*        this.props.stores.map((store, i) => {*/}
          {/*          return (*/}
          {/*            <option value={store.storeNumber} key={i}>*/}
          {/*              {" "}*/}
          {/*              {store.storeNumber} - {store.address}*/}
          {/*            </option>*/}
          {/*          );*/}
          {/*        })}*/}
          {/*    </select>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <HcFormGroup>
            <HcSubmitButton
              submit={() => this.save()}
              text="Apply"
              isValid={true}
            />
            <HcClearButton clear={() => this.clear()} text="Clear" />
          </HcFormGroup>
        </div>
      );
  }
}
