import "../node_modules/bootstrap/dist/css/bootstrap.css";
import '../node_modules/font-awesome/css/font-awesome.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { AppRouter } from './components/router';


// @ts-ignore
ReactDOM.render(<AppRouter />, document.getElementById('root'));
