import axios from "axios";
import { actionTypes } from "../common/constants";
import { AppConstants } from "../common/constants";
import { Clinic } from "../models";
const client = axios.create({ baseURL: AppConstants.serverUrl });

export const getAllClinics = () => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.GET_ALL_CLINICS_LOADING });
  client.get(`/api/clinics/getAllClinics`).then(
    (response: any) => {
      dispatch(fetchClinicsCompleted(response.data));
    },
    error => {
      dispatch(fetchClinicsFailed(error));
    }
  );
};

const fetchClinicsCompleted = (clinics: any) => ({
  payload: clinics,
  type: actionTypes.GET_ALL_CLINICS_COMPLETED
});

const fetchClinicsFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_ALL_CLINICS_FAILED
});

export const getClinic = (id: number) => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.GET_CLINIC_LOADING });
  client.get(`/api/clinics/getClinic?id=${id}`).then(
    (response: any) => {
      dispatch(fetchClinicCompleted(response.data));
    },
    error => {
      dispatch(fetchClinicFailed(error));
    }
  );
};

const fetchClinicCompleted = (clinic: any) => ({
  payload: clinic,
  type: actionTypes.GET_CLINIC_COMPLETED
});

const fetchClinicFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_CLINIC_FAILED
});

export const updateClinic = (clinic: Clinic) => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.UPDATE_CLINIC_LOADING });
  client.post(`/api/clinics/updateClinic`, clinic).then(
    (response: any) => {
      dispatch(updateClinicCompleted(response.data));
    },
    error => {
      dispatch(updateClinicFailed(error));
    }
  );
};

const updateClinicCompleted = (clinic: any) => ({
  payload: clinic,
  type: actionTypes.UPDATE_CLINIC_COMPLETED
});

const updateClinicFailed = (error: any) => ({
  payload: error,
  type: actionTypes.UPDATE_CLINIC_FAILED
});

export const deleteClinic = (clinic: Clinic) => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.DELETE_CLINIC_LOADING });
  client.post(`/api/clinics/deleteClinic`, clinic).then(
    (response: any) => {
      dispatch(deleteClinicCompleted(response));
    },
    error => {
      dispatch(deleteClinicFailed(error));
    }
  );
};

const deleteClinicCompleted = (reponse: any) => ({
  payload: reponse,
  type: actionTypes.DELETE_CLINIC_COMPLETED
});

const deleteClinicFailed = (error: any) => ({
  payload: error,
  type: actionTypes.DELETE_CLINIC_FAILED
});

export const createClinic = (clinic: Clinic) => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.CREATE_CLINIC_LOADING });
  const geocode = axios.create({ baseURL: AppConstants.geocodeUrl });
  geocode
    .get(`${clinic.address}, ${clinic.city}, ${clinic.state} ${clinic.zipcode}`)
    .then(
      (georesponse: any) => {
        clinic.latitude = georesponse.data.results[0].position.lat;
        clinic.longitude = georesponse.data.results[0].position.lon;

        client.put(`/api/clinics/createClinic`, clinic).then(
          (response: any) => {
            dispatch(createClinicCompleted(response.data));
          },
          error => {
            dispatch(createClinicFailed(error));
          }
        );
      },
      error => {
        dispatch(createClinicFailed(error));
      }
    );
};

const createClinicCompleted = (clinic: any) => ({
  payload: clinic,
  type: actionTypes.CREATE_CLINIC_COMPLETED
});

const createClinicFailed = (error: any) => ({
  payload: error,
  type: actionTypes.CREATE_CLINIC_FAILED
});
