import axios from "axios";
import { actionTypes } from "../common/constants";
import { AppConstants } from "../common/constants";
const client = axios.create({ baseURL: AppConstants.serverUrl });

export const getReportData = () => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.GET_REPORT_DATA_LOADING });
  client.get(`/api/reports/getReportData`).then(
    (response: any) => {
      dispatch(getReportDataCompleted(response.data));
    },
    error => {
      dispatch(getReportDataFailed(error));
    }
  );
};

const getReportDataCompleted = (profile: any) => ({
  payload: profile,
  type: actionTypes.GET_REPORT_DATA_COMPLETED
});

const getReportDataFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_REPORT_DATA_FAILED
});

export const getExportData = () => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.GET_EXPORT_DATA_LOADING });
  client.get(`/api/reports/getExportData`).then(
    (response: any) => {
      dispatch(getExportDataCompleted(response.data));
    },
    error => {
      dispatch(getExportDataFailed(error));
    }
  );
};

const getExportDataCompleted = (profile: any) => ({
  payload: profile,
  type: actionTypes.GET_EXPORT_DATA_COMPLETED
});

const getExportDataFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_EXPORT_DATA_FAILED
});
