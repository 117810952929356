import React from "react";
import { User, Role } from "../../models";
import { Nav } from "./nav";

const Header = (
  usersName: string,
  userId: number,
  role: Role,
  nav: string,
  isMobile: boolean
) => {
  return (
    <div className="header row">
      <Nav role={role} nav={nav} isMobile={isMobile} />
      <div className="col" style={{ flexGrow: 0.6 }}>
        <div className="user">
          <div className="name">{usersName}</div>
          <div className="links">
            <a href={"/#/profile/" + userId}>Profile</a> |{" "}
            <a href={"/#/"} onClick={logout}>
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const logout = () => {
  window.localStorage.removeItem("userId");
};

type AuthHeaderProps = { user: User };
const AuthHeader = ({ user }: AuthHeaderProps) => {
  const nav = window.location.hash.split("#")[1].split("/")[1];
  if (user) {
    return Header(
      `${user.firstName || ""} ${user.lastName || ""}`,
      user.ID,
      user.role,
      nav,
      user.isMobile
    );
  }
  return <div />;
};

export default AuthHeader;
