import axios from "axios";
import { actionTypes } from "../common/constants";
import { AppConstants } from "../common/constants";
const client = axios.create({ baseURL: AppConstants.serverUrl });

export const getAllStores = () => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.GET_STORES_LOADING });
  client.get(`/api/stores/getAllStores`).then(
    (response: any) => {
      dispatch(fetchStoresCompleted(response.data));
    },
    error => {
      dispatch(fetchStoresFailed(error));
    }
  );
};

const fetchStoresCompleted = (stores: any) => ({
  payload: stores,
  type: actionTypes.GET_STORES_COMPLETED
});

const fetchStoresFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_STORES_FAILED
});

export const getAllRegions = () => (dispatch: any) => {
  dispatch({ payload: {}, type: actionTypes.GET_REGIONS_LOADING });
  client.get(`/api/stores/getAllRegions`).then(
      (response: any) => {
        dispatch(fetchRegionsCompleted(response.data));
      },
      error => {
        dispatch(fetchRegionsFailed(error));
      }
  );
};

const fetchRegionsCompleted = (stores: any) => ({
  payload: stores,
  type: actionTypes.GET_REGIONS_COMPLETED
});

const fetchRegionsFailed = (error: any) => ({
  payload: error,
  type: actionTypes.GET_REGIONS_FAILED
});
