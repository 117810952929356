import * as React from "react";

interface ErrorMessageProps {
  error: string;
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  return (
    <div style={{backgroundColor: "white", padding: "5em"}}>
      <h2>Uh-oh!</h2>
      <p className="align-text-left">
        There was an error loading data from the server:
        <br />
        <br />
        <code>{error}</code>
        <br />
      </p>
      <p
        className="align-text-left"
        style={{
          cursor: "pointer",
          textDecoration: "underline"
        }}
        onClick={() => window.location.reload()}
      >
        Click here to try again
      </p>
    </div>
  );
};
