import React from "react";
import { getIconClass } from "../../../common/util";
import ReactMarkdown from "react-markdown";

type Props = {
  status: string;
  text: string;
  statusId: number;
};
export const HcFinalAssessment = (props: Props) => {
  function LinkRenderer(props: any) {
    return <a href={props.href} target="_blank">{props.children}</a>
  }

  if (!props.statusId) return null;
  const { status, text, statusId } = props;
  return (
    <>
      <div className="row">
        <div className={`question final-assessment ${status.toLowerCase()}`}>
          <div className={"chatLight col-2"}>
            <span className={getIconClass(statusId)}>
              <span style={{ paddingLeft: 30, width: 190 }}>{status}</span>
            </span>
          </div>
          <ReactMarkdown source={text} renderers={{link: LinkRenderer}}/>
        </div>
      </div>
    </>
  );
};
