import * as React from 'react';
import {Clinic} from "../../models";
import { ErrorMessage } from '../shared/ErrorMessage';
import { Loading } from '../shared/Loading';

interface Props {
    location: any;
    history: any;
    clinic: Clinic;
    clinicLoading: boolean;
    clinicError: string;

    fetchClinic(id: number): void;
    updateClinic(clinic: Clinic): void;
    createClinic(clinic: Clinic): void;

}

export class ClinicEdit extends React.Component<Props,{clinic:any}> {

    constructor(props: any) {
        super(props);

        this.state = {
            clinic: this.props.clinic
        };

        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const pathId = this.props.location.pathname.toString().replace('/clinic/edit/','');

        if(pathId === 'new'){
            this.setState({clinic: {}});
        }else{
            this.props.fetchClinic(pathId);
        }
    }

    save() {
        const pathId = this.props.location.pathname.toString().replace('/clinic/edit/','');

        if(pathId === 'new'){
            this.state.clinic.status = 'Active';
            this.props.createClinic(this.state.clinic);
        }else{
            this.props.updateClinic(this.state.clinic);
        }

        setTimeout(() => {this.props.history.push('/clinics');}, 1000);
    }

    cancel() {
        this.props.history.push('/clinics');
    }

    handleChange(event: any) {
        const clinic: Clinic = this.state.clinic;
        if(event.target.id === 'clinicName'){
            clinic.clinicName = event.target.value;
        }else if(event.target.id === 'address'){
            clinic.address = event.target.value;
        }else if(event.target.id === 'city'){
            clinic.city = event.target.value;
        }else if(event.target.id === 'state'){
            clinic.state = event.target.value;
        }else if(event.target.id === 'zip'){
            clinic.zipcode = event.target.value;
        }else if(event.target.id === 'email'){
            clinic.email = event.target.value;
        }else if(event.target.id === 'phone'){
            clinic.phoneNumber = event.target.value;
        }else if(event.target.id === 'staff'){
            clinic.staff = event.target.value;
        }

        this.setState({clinic: clinic});
    }

    render() {
        if (this.props.clinicLoading) return <Loading />;
        if (this.props.clinicError)
            return <ErrorMessage error={this.props.clinicError} />;
      
        return (
            <div className="clinicsContainer">
                <button onClick={this.save} className="save">SAVE</button>
                <button onClick={this.cancel} className="cancel" >CANCEL</button>
                <h2>{this.props.location.pathname.toString().replace('/clinic/edit/','') === 'new' ? 'New Clinic' : 'Update Clinic'}</h2>
                <form>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="clinicName">Clinic Name</label>
                                <input onChange={this.handleChange} className="form-control" id="clinicName" value={this.state.clinic.clinicName}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <input onChange={this.handleChange} className="form-control" id="address" value={this.state.clinic.address}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input onChange={this.handleChange} className="form-control" id="city" value={this.state.clinic.city}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="state">State</label>
                                <input onChange={this.handleChange} className="form-control" id="state" value={this.state.clinic.state}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="zip">Zip Code</label>
                                <input onChange={this.handleChange} className="form-control" id="zip" value={this.state.clinic.zipcode}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input onChange={this.handleChange} className="form-control" id="email" value={this.state.clinic.email}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="phone">Phone Number</label>
                                <input onChange={this.handleChange} className="form-control" id="phone" value={this.state.clinic.phoneNumber}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="staff">Staff</label>
                                <textarea onChange={this.handleChange} className="form-control" id="staff" value={this.state.clinic.staff}></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
};
