import { connect } from 'react-redux';
import { ReduxState } from '../../../store/reducers';
import {getProfileByUser, updateUserProfile} from "../../../actions/userAction";
import {User} from "../../../models";
import {EmployeeProfile} from "./employeeProfile";
import {getAllStores} from "../../../actions/storeAction";

const mapStateToProps = (state: ReduxState) => ({
    user: state.user.data,
    userLoading: state.user.loading,
    userError: state.user.error,

    stores: state.stores.data,
    storesLoading: state.stores.loading,
    storesError: state.stores.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUserProfile: (id: number, isMobile: boolean) => dispatch(getProfileByUser(id, isMobile)),
    updateUserProfile: (user: User) => dispatch(updateUserProfile(user)),
    fetchStores: () => dispatch(getAllStores())
});

export const EmployeeProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmployeeProfile);