import React from "react";

type Props = {
  clear: Function;
  text?: string;
  [key: string]: any;
};
export const HcClearButton = (props: Props) => {
  const { clear, text, rest } = props;
  return (
    <>
      <button
        className="hc btn alt"
        type="button"
        onClick={e => {
          e.preventDefault();
          clear();
        }}
        {...rest}
      >
        {text ? text : "Clear"}
      </button>
    </>
  );
};
