import { connect } from 'react-redux';
import { ReduxState } from '../../store/reducers';
import { ManagerDashboard } from './managerDashboard';
import {
    getAllUsersAndAssessmentsByStore
} from "../../actions/assessmentAction";

const mapStateToProps = (state: ReduxState) => ({
    usersAndAssessmentsByStore: state.usersAndAssessmentsByStore.data,
    usersAndAssessmentsByStoreLoading: state.usersAndAssessmentsByStore.loading,
    usersAndAssessmentsByStoreError: state.usersAndAssessmentsByStore.error,

    user: state.user.data,
    userLoading: state.user.loading,
    userError: state.user.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchAllUsersAndAssessmentsByStore: (storeId: string) => dispatch(getAllUsersAndAssessmentsByStore(storeId))
});

export const ManagerDashboardContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ManagerDashboard);