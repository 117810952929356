import React, {Component} from "react";
import {FilterAssessmentsCriteria, User} from "../../models";
import {ChatHistory} from "../employee/chat/components/chatHistory";

import {
    ChatProps,
    ChatState as State,
    DefaultChatComponentState as DefaultState
} from "../employee/helpers";
import {HcPreviousConversation} from "../shared/assessments/HcPreviousConversation";
import { Loading } from "../shared/Loading";
import { ErrorMessage } from "../shared/ErrorMessage";

interface Props extends ChatProps{
    userAssessments: User;
    userAssessmentsLoading: boolean;
    userAssessmentsError: string;
    getAssessmentsByUser(id: number): void;
}

export class AssessmentLogList extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = DefaultState;
    }

    async componentDidMount() {
        const userId = this.props.location.pathname.toString().replace('/assessmentLogs/','');
        this.props.getAssessmentsByUser(userId);
        this.props.getChatData(userId);
    }

    componentDidUpdate(prevProps:any): void {
        if (this.props.conversationHistory !== prevProps.conversationHistory) {
            this.setState({conversationHistory: this.props.conversationHistory});
        }
    }

    close() {
        window.location.href = '/#/assessmentLogs';
    }

    toggleFiltering = (val?: boolean) => {
        this.setState({
            filtering: val !== null ? val === true : !this.state.filtering
        });
    };

    render() {
        if (this.props.userAssessmentsLoading) return <Loading />;
        if (this.props.userAssessmentsError)
            return <ErrorMessage error={this.props.userAssessmentsError} />;
      
        let selectedConversation = this.props.conversationHistory ? this.props.conversationHistory : { id: 0 };
        return (
            <div className="assessmentContainer">
                <div className="row">
                    <div className="col-3 chatHistoryContainer">
                        <div className="selectedUser">
                            <i className="userClose" onClick={this.close}></i>
                            <p className="name">{this.props.userAssessments.firstName} {this.props.userAssessments.lastName}</p>
                            <p>{this.props.userAssessments.title}</p>
                            <p>{this.props.userAssessments.storeNumber ? `Store # ${this.props.userAssessments.storeNumber}` : ''}</p>
                        </div>
                        <ChatHistory
                            startAssessment={undefined}
                            chatHistory={this.props.chatData.chatHistory}
                            selectedConversationId={+selectedConversation.id}
                            selectPreviousChat={(chatId: number) => {
                                this.props.getConversationHistory(
                                    this.props.userAssessments.ID,
                                    chatId
                                );
                            }}
                            filtering={this.state.filtering}
                            toggleFiltering={this.toggleFiltering}
                            filterAssessments={(criteria: FilterAssessmentsCriteria) =>
                                this.props.filterAssessments(this.props.userAssessments.ID, criteria)
                            }
                        />
                    </div>
                    <div className={"chatDetail "+this.props.user.isMobile ? "col" : "col-9"} style={{ height: 800, padding: 0 }}>
                        {!selectedConversation.hasOwnProperty("items") ? (
                            <div className="chatBot">
                                <div className="emptyAssessment">
                                    <p className="startAssessmentText">Select An Assessment</p>
                                    <p className="selectAssessmentText">
                                        You can use the filter and search tools to target conversations.
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <HcPreviousConversation conversation={this.props.conversationHistory} shouldScrollToBottom={false} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
