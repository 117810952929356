import http from "../../common/http";
import { EmployeeConstants as constants } from "./constants";
import { ChatData } from "./helpers";
import { getUriPath } from "../../common/util";
import {
  Assessment,
  FilterAssessmentsCriteria,
  Conversation
} from "../../models";

/** Use the API to get the token using a server setting secret */
export const getChatData = (id: string) => async (dispatch: any) => {
  try {
    dispatch({type: constants.Actions.GET_CONVERSATION_TOKEN_LOADING, payload: {}});
    const response = await http.get<ChatData>(
      getUriPath(constants.Api.getChatData, { id })
    );
    dispatch({
      payload: response,
      type: constants.Actions.GET_CONVERSATION_TOKEN
    });
  } catch (ex) {
    dispatch({
      payload: ex,
      type: constants.Actions.GET_CONVERSATION_TOKEN_ERROR
    });
  }
};

export const getConversationHistory = (id: number, chatId: number) => async (
  dispatch: any
) => {
  try {
    dispatch({type: constants.Actions.GET_CONVERSATION_HISTORY_LOADING, payload: {}});
    const response = await http.get<ChatData>(
      getUriPath(constants.Api.getConversationHistory, { id, chatId })
    );
    dispatch({
      payload: response,
      type: constants.Actions.GET_CONVERSATION_HISTORY
    });
  } catch (ex) {
    dispatch({
      payload: ex,
      type: constants.Actions.GET_CONVERSATION_HISTORY_ERROR
    });
  }
};

export const filterAssessments = (
  id: number,
  criteria: FilterAssessmentsCriteria
) => async (dispatch: any) => {
  try {
    dispatch({type: constants.Actions.FILTER_ASSESSMENTS_LOADING, payload: {}});
    const response = await http.post<Assessment[]>(
      getUriPath(constants.Api.filterAssessments, { id }),
      criteria
    );
    dispatch({
      payload: response,
      type: constants.Actions.FILTER_ASSESSMENTS
    });
  } catch (ex) {
    dispatch({
      payload: ex,
      type: constants.Actions.FILTER_ASSESSMENTS_ERROR
    });
  }
};

export type eocPostResponse = {
  chatData: ChatData;
  conversationHistory: Conversation;
};
export const endOfConversation = (id: string, chatId: string, adminId: number|undefined) => async (
  dispatch: any
) => {
  try {
    dispatch({type: constants.Actions.CHATDATA_EOC_LOADING, payload: {}});
    const response =
      (await http.get<eocPostResponse>(
        getUriPath(constants.Api.eoc, { id, chatId, adminId })
      )) || ({} as eocPostResponse);
      dispatch({
        payload: response.chatData,
        type: constants.Actions.CHATDATA_EOC
      });
      dispatch({
        payload: response.conversationHistory,
        type: constants.Actions.CONVO_EOC
      });
  } catch (ex) {
    dispatch({
      payload: ex,
      type: constants.Actions.CHATDATA_EOC_ERROR
    });
  }
};
