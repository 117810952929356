import { EmployeeConstants as constants } from "./constants";
import { ChatData } from "./helpers";
import { Assessment, Conversation } from "../../models";
import { CommonState, DefaultCommonState } from "../../store/reducers";
import { CommonStateLoading, CommonStateError, CommonStateReducer } from "../../common/util";

export const chatReducer = (
  state: CommonState<ChatData> = DefaultCommonState,
  action: any
): CommonState<ChatData> => {
  switch (action.type) {
    case constants.Actions.GET_CONVERSATION_TOKEN_LOADING:
      return CommonStateLoading(state);
    case constants.Actions.GET_CONVERSATION_TOKEN:
      return CommonStateReducer(action.payload, state);
    case constants.Actions.GET_CONVERSATION_TOKEN_ERROR:
      return CommonStateError(action.payload);

    case constants.Actions.FILTER_ASSESSMENTS_LOADING:
      return CommonStateLoading(state);
    case constants.Actions.FILTER_ASSESSMENTS:
      return handleFilterAssessments(state, action.payload);
    case constants.Actions.FILTER_ASSESSMENTS_ERROR:
      return CommonStateError(action.payload);

    case constants.Actions.CHATDATA_EOC_LOADING:
      return CommonStateLoading(state);
    case constants.Actions.CHATDATA_EOC:
      return CommonStateReducer(action.payload, state);
    case constants.Actions.CHATDATA_EOC_ERROR:
      return CommonStateError(action.payload);
  }

  return state;
};

const handleFilterAssessments = (
  state: CommonState<ChatData>,
  payload: Assessment[]
): CommonState<ChatData> => {
  return {
    loading: false,
    error: "",
    data: {
      chatHistory: payload || []
    } as ChatData
  };
};

export const conversationHistoryReducer = (
  state: CommonState<Conversation> = DefaultCommonState,
  action: any
): CommonState<Conversation> => {
  switch (action.type) {
    case constants.Actions.GET_CONVERSATION_HISTORY_LOADING:
      return CommonStateLoading(state);
    case constants.Actions.GET_CONVERSATION_HISTORY:
      return CommonStateReducer(action.payload);
    case constants.Actions.GET_CONVERSATION_HISTORY_ERROR:
      return CommonStateError(action.payload);

    case constants.Actions.CONVO_EOC_LOADING:
      return CommonStateLoading(state);
    case constants.Actions.CONVO_EOC:
      return CommonStateReducer(action.payload, state)
    case constants.Actions.CONVO_EOC_ERROR:
      return CommonStateError(action.payload);
  }

  return state;
};
